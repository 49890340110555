@use '../../abstracts/' as *;


.skyscraper {
  margin: 30px 0;
}

.skyscraper__item {
  margin-bottom: 35px;

  &:last-child {
    margin-bottom: 0;
  }
}

.skyscraper__title {
  margin: 24px 0;
}

.skyscraper__img {
  width: 100%;
}

.skyscraper__action {
  margin-top: 16px;
}

.skyscraper__link {
  &:hover, &:focus-visible {
    font-weight: $fontWeight-bold;
  }
}

.skyscraper__btn {
  vertical-align: middle;
  .icon {
    display: inline-block;
    height: 15px;
    width: 15px;
    margin-left: 5px;
    transition: 0.2s margin ease-in;
  }

  &:hover, &:focus-visible {
    .icon {
      margin-left: 10px;
    }
  }
}

@media(min-width: $bp-sw) {
  .skyscraper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 20px;
  }
}

