@use '../../abstracts' as *;

.products-detail__header {
  margin-bottom: 40px;
}

.products-detail__status {
  background: $color-yellow;
  border: 1px solid $color-black;
  font-size: 0.875rem;
  font-weight: $fontWeight-bold;
  padding: 5px 15px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.products-detail__title {
  font-size: 1.125rem;
}

.products-detail__data {
  display: none;
}

.products-detail__media {
  position: relative;
}

.products-detail__media-img {
  background: $color-light-grey;
  padding: 50px;
  text-align: center;
}

/* Styling for Image Modal */

.products-detail__expand {
  background: $color-primary;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 48px;
  width: 48px;

  .icon {
    height: 20px;
    width: 20px;
    fill: $color-white;
  }
}

.products-detail__list {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  gap: 16px 48px;
  @extend %listreset;
}

.products-detail__item {
  border-bottom: 1px solid $color-base;
  padding: 32px 0;
}

.products-detail__item-value {
  font-size: 1.5rem;
  font-weight: $fontWeight-bold;
  margin: 0;
}

.products-detail__description {
  margin-bottom: 15px;
}

.products-detail__action {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.products-detail__btn {
  width: 100%;

  svg {
    height: 24px;
    width: 24px;
  }
}

/* Tab styling for Product Details */

.tab-layout--products {
  margin-top: 40px;

  .tab-layout__panel {
    padding: 30px 0;
  }

  .tab-layout__tab {
    border: none;
    background: transparent;
    color: $color-dark-grey;
    cursor: pointer;
    position: relative;
    padding: 10px 5px;

    &:hover,
    &:focus-visible,
    &.active {
      color: $color-blue;

      &:after {
        content: "";
        background: $color-blue;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 100%;
      }

    }

  }
}

.products-detail__item-label {
  position: relative;
}

.products-detail__benefits {
  @extend %listreset;
}

.products-detail__benefits-item {
  border-bottom: 1px solid $color-base;
  padding-top: 15px;
  margin-bottom: 30px;
}

.products-detail__benefits-title {
  font-size: 1.5rem;
  font-weight: $fontWeight-bold;
}

.products-detail__benefits-text {
  margin: 25px 0;
}

.products-detail__item-label {
  display: flex;
  align-items: center;
}


@media(min-width: $bp-m) {
  .products-detail__header {
    display: flex;
    align-items: center;
  }

  .products-detail__title,
  .products-detail__data {
    width: 50%;
  }

  .products-detail__data {
    display: block;
    text-align: right;
  }

  .products-detail__body {
    display: flex;
    align-items: flex-start;
    column-gap: 40px;
  }

  .products-detail__media {
    display: flex;
    align-items: flex-start;
    width: 50%;
  }

  .products-detail__content {
    width: 50%;
  }

  .products-detail__expand {
    position: relative;
    left: 0;
    right: auto;
  }

  .products-detail__action {
    margin-top: 0;
  }

  .products-detail__btn {
    width: auto;
  }

}

@media(min-width: $bp-l) {
  .products-detail__media {
    width: 30%;
  }

  .products-detail__content {
    width: 70%;
  }

  .products-detail__media-img {
    padding: 64px 72px;
  }

  .products-detail__title {
    width: 30%;
  }

  .products-detail__data {
    width: 70%;
  }

  .products-detail__list {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }

  .products-detail__item-value {
    font-size: 2rem;
  }

  .products-detail__benefits-item {
    display: flex;
    align-items: center;
  }

  .products-detail__benefits-title,
  .products-detail__benefits-text {
    width: 50%;
  }

  .products-detail__benefits-title {
    font-size: 2rem;
  }
}