@use '../../abstracts/' as *;

//Articles Search Header

.article__header {
  margin-top: 40px;
}

.articles__header-search {
  position: relative;
  margin-bottom: 24px;
}

.articles__header-input{
  border: 0;
  border-bottom: 1px solid $color-base;
  border-radius: 0;
  height: 40px;
  width: 100%;
}

.articles__header-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 5px;
  height: 40px;
  width: 40px;
  transform: translateY(-50%);

  .icon {
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
}

.articles__header-filters{
  display: flex;
  gap: 15px;
  list-style: none;
  margin: 0;
  max-width: 100%;
  overflow-x: auto;
  padding: 15px;
}

.articles__header-item {
  flex: 1 0 auto;
}

.articles__header-label {
  background: transparent;
  border: 1px solid $color-dark-blue;
  color: $color-dark-blue;
  cursor: pointer;
  display: inline-block;
  border-radius: 30px;
  padding: 8px 16px;
  text-transform: capitalize;

  &.checked {
    background: $color-dark-blue;
    color: $color-white;
  }
}

.article__results-none {
  margin: 40px 0;
  text-align: center;
  grid-column: 1/4;
}

//Grid Articles

.article__results{
  margin-top: 40px;
}

.article-grid{
  list-style: none;
  margin: 0;
  padding: 0;
}

.article-grid__item {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.article-grid__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  list-style: none;
  margin: 0 0 16px 0;
  padding: 0;
}

.article-grid__tags-item {
  display: inline-block;
  background: transparent;
  border: 1px solid $color-base;
  padding: 4px 16px;
  text-transform: uppercase;
}

.article-grid__title {
  margin-bottom: 16px;
}

.article-grid__summary {
  margin-bottom: 24px;
}

.article-grid__media {
  position: relative;
  height: 260px;
}

.article-grid__content {
  padding: 16px;
}

.article-grid__img {
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.articles__header-filter {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.article-grid__link {
  color: $color-base;
  text-decoration: underline;
}

.articles-grid__more {
  margin: 98px 0;
  text-align: center;
}

@media(min-width: $bp-m) {
  .article-grid {
    display: grid;
    grid-template-columns:  repeat(2, minmax(45%, 1fr));
    column-gap: 16px;
    row-gap: 20px;
  }
}

@media(min-width: $bp-l) {
  .article__header {
    display: flex;
    align-items: flex-start;
  }

  .articles__header-label {    
    &:hover,&:focus-visible {
      background: $color-dark-blue;
      color: $color-white;
    }
  }

  .articles__header-filters {
    flex-wrap: wrap;
  }

  .article-grid {
    grid-template-columns: repeat(3, minmax(30%, 1fr));
    row-gap: 40px;
    margin: 0;
    padding: 0;
  }

  .articles__header-category {
    flex-wrap: wrap;
    overflow: visible;
    padding: 0;
    width: 85%;
  }

  .articles__header-item {
    flex: 0 auto;
  }

  .articles__header-search {
    order: 2;
    width: 15%;
  }

  .article-grid__media {
    display: none;
  }

  .article-grid__item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;

    .article-grid__title {
      line-height: 1.3;
    }
  }

  .article-grid--display {
    .article-grid__item {
      &.first{

        align-content: flex-end;
        position: relative;
        grid-column: 1/3;
        grid-row: 1/3;
        .article-grid__media {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
        }

        .article-grid__content {
          position: relative;
          background: $color-dark-blue;
          color: $color-white;
          margin-bottom: 52px;
          padding: 40px 32px 40px 72px;
          width: 50%;
        }

        .article-grid__tags-item {
          border-color: $color-white;
        }

        .article-grid__link {
          color: $color-white
        }
      }

      &.stacked {
        grid-column: 3;
      }

      &.landscape {
        display: grid;
        grid-template-columns: 50% 50%;

        .article-grid__media {
          display: block;
          height: 100%;
        }

        .article-grid__content {
          position: relative;
          background: $color-dark-blue;
          color: $color-white;
          padding: 80px 40px;
        }

        .article-grid__tags-item {
          border-color: $color-white;
        }

        .article-grid__link {
          color: $color-white
        }
      }

      &.left {
        grid-column: 2/4;

        .article-grid__media {
          order: 2;
        }
      }

      &.right{
        grid-column: 1/3;
      }
      
    }  

  }
}