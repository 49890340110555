@use "../abstracts" as *;

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  font-family: var(--font-heading);
  line-height: 1.125;
  margin-bottom: $typography-margin-bottom;
}

h1,
.h1 {
  font-size: #{"clamp(2.5rem,3.5vw,4.5rem)"};
}

.h2-alt {
  font-size: #{"clamp(2rem, 3vw, 3rem)"};
  line-height: 1.25;
}

h2,
.h2 {
  font-size: #{"clamp(2rem, 3vw, 3.5rem)"};
  line-height: 1.5;
}

h3,
.h3 {
  font-family: var(--font-secondary);
  font-size: #{"clamp(1.5rem, 2.5vw, 2.5rem)"};
  font-weight: $fontWeight-light;
}

.h3--bold {
  font-weight: $fontWeight-bold;
}

h4,
.h4 {
  font-size: #{"clamp(1.5rem, 3vw, 2rem)"};
}

h5,
.h5 {
  font-size: #{"clamp(1.25rem, 3.5vw, 1.6rem)"};
}

h6,
.h6 {
  font-size: #{"clamp(1rem, 3vw, 1.25rem)"};
}

a {
  color: $color-primary;
  font-weight: $fontWeight-bold;
  letter-spacing: 1px;
  text-decoration: underline;

  &:hover,&:focus {
    text-decoration: none;
  }
}

b,
strong {
  font-weight: $fontWeight-bold;
}

p {
  margin: 0 0 $typography-margin-bottom;
}

// Very simple blockquote styling
blockquote {
  margin: 1em 1.5em;
  padding-left: 1.5em;
  border-left: 5px solid hsl(0, 0%, 85%);
}

//Link Styles
.arrow-forward {
  display: inline-block;
  color: $color-primary;
  font-weight: $fontWeight-normal;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  vertical-align: middle;

  &:after {
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    transition: 0.2s margin ease-in;
  }
}

.arrow-forward--yellow {
  color: $color-yellow;
  &:after {
    content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 18"><title>arrow-right</title><path fill="%23FFDD00" d="M12.15 0.901l-1.125 1.125 6.075 6.188h-17.1v1.575h17.1l-6.075 6.188 1.125 1.125 8.1-8.1-8.1-8.1z"></path></svg>');
  }

  &:hover,
  &:focus-visible {
    &:after {
      margin-left: 15px;
    }
  }
}

.arrow-forward--yellow-up {
  color: $color-yellow;
  &:after {
    content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><title>arrow-up</title><path fill="%23FFDD00" d="M5.625 3.375v1.125h7.082l-9.332 9.332 0.793 0.793 9.332-9.332v7.082h1.125v-9h-9z"></path></svg>');
  }

  &:hover,
  &:focus-visible {
    &:after {
      animation: fadeInUp 0.3s ease-in;
    }
  }
}

.arrow-forward--blue {
  &:after {
    content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 18"><title>arrow-right</title><path fill="%230a2336" d="M12.15 0.901l-1.125 1.125 6.075 6.188h-17.1v1.575h17.1l-6.075 6.188 1.125 1.125 8.1-8.1-8.1-8.1z"></path></svg>');
  }

  &:hover,
  &:focus-visible {
    &:after {
      margin-left: 15px;
    }
  }
}

.arrow-forward--blue-up {
  &:after {
    content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><title>arrow-up</title><path fill="%230a2336" d="M5.625 3.375v1.125h7.082l-9.332 9.332 0.793 0.793 9.332-9.332v7.082h1.125v-9h-9z"></path></svg>');
  }

  &:hover,
  &:focus-visible {
    &:after {
      animation: fadeInUp 0.3s ease-in;
    }
  }
}
