@use '../../abstracts/' as *;

.accordion-list {
}

.accordion-list__list {
    @extend %listreset;
}

.accordion-item {
    border-bottom: 1px solid $color-black;
}

.accordion-item__title {
    margin: 0;
}

.accordion-item__toggle {
    @extend %buttonreset;
    position: relative;
    font-weight: $fontWeight-bold;
    padding: 1em 40px 1em 0;
    width: 100%;
    text-align: left;
    &::before,&::after {
        content: '';
        width: 28px;
        height: 3px;
        background: $color-black;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: .3s ease-in transform;
        right: 7px;
    }
    &::after {
        transform: translateY(-50%) rotate(90deg);
    }
    &.active {
        &::after {
            transform: translateY(-50%) rotate(0);
        }    
    }
}

.accordion-item__content {
    display: none;
    padding: 0 0 24px;
    font-size: 1.25rem;
}
