@use '../../abstracts/' as *;


// Small Cards - Blue and Dark Blue
.card-small {
  background: $color-primary;
  padding: 32px;
  width: 90%;
}

.card-small--dark {
  background: $color-dark-blue;
}

.card-small__title {
  color: $color-white;
  margin-bottom: 32px;
}

.card-small__action {
  .button {
    display: block;
    margin-bottom: 24px;
    position: relative;
  }
}

@media(min-width: $bp-m) {
  .card-small {
    width: 100%;
  }
}

@media(min-width: $bp-l) {
  .card-small {
    padding: 32px 72px;
  }  

  .card-small__action .button {
    display: inline-block;
  }
}

// Large Cards - Blue and White

.card-large {
  background: $color-primary;
  height: 100%;
  padding: 80px 25px;
  color: $color-white;
}

.card-large__title {
  color: $color-white;
  font-weight: $fontWeight-normal;
}

.card-large__action {
  margin-top: 64px;
  .button {
    display: block;
    width: 100%;
  }
}

.card-large--white {
  background: $color-white;
  color: $color-base;
  .card-large__title {
    color: $color-base;
  }
}

@media(min-width: $bp-m) {
  .card-large {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 56px;
  }  

  .card-large__action {
    margin-top: 140px;
    .button {
      display: inline-block;
      width: auto;
    }    
  }

}

//Card List

.card-list {
  margin: 20px;
}

.card-list__item {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.card-list__title {
  font-weight: $fontWeight-normal;
  margin: 24px 0;
}

.card-list__action {
  margin-top: 40px;
}

@media(min-width: $bp-mw) {
  .card-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 42px;
  }

  .card-list__item {
    margin-bottom: 0;
  }
}