@use "../../abstracts/" as *;

.partner__finder {
  background: $color-primary;
  color: $color-white;
  padding: 25px;
}

.partner__group {
  margin-bottom: 30px;
}

.partner__group--form {
  .partner__group-input {
    width: 100%;
  }

  .partner__finder-action {
    display: flex;
    margin-top: 10px;
  }

  .partner__finder-action-btn,
  .partner__finder-search-btn {
    width: 50%;
  }
}

.partner__finder-search-btn {
  background: transparent;
  border: 1px solid $color-yellow;
  padding: 10px 20px;
  height: 45px;
  .icon {
    height: 24px;
    width: 24px;
    fill: $color-yellow;
  }
}

.partner__group-label {
  display: block;
  font-size: 0.9rem;
  color: $color-light-grey-2;
  margin-bottom: 5px;
}

.partner__group-input {
  border: none;
  border-bottom: 1px solid $color-light-grey;
  background: transparent;
  color: $color-white;
  display: block;
  height: 45px;
  width: 100%;

  &::selection {
    background: $color-blue-highlight;
  }
}

.partner__group-fieldset {
  margin-top: 20px;
}

.partner__check {
  margin-bottom: 10px;
}

.partner__finder-action-btn {
  display: inline-block;
  background: $color-yellow;
  cursor: pointer;
  border: none;

  .icon {
    height: 40px;
    width: 40px;
  }
}

.partner__finder-action--desktop {
  display: none;
}

.partner__container {
  display: none;
}

.partner__results {
  position: relative;
}

.partner__list {
  padding-top: 30px;
  padding: 20px;
  max-height: 600px;
  overflow-y: auto;
}

.partner__item-detail {
  &.active + .partner__detail {
    display: block;
  }
}

.partner__detail {
  background: $color-dark-blue;
  padding: 20px;
  color: $color-white;
  width: 300px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  width: 100%;
}

.partner__detail-close {
  border: none;
  background: transparent;
  margin-bottom: 15px;
  cursor: pointer;

  .icon {
    height: 24px;
    width: 24px;
    fill: $color-white;
  }
}

.partner__item-action,
.partner__detail-action {
  text-align: right;
}

.partner__item-action {
  margin-top: 15px;
}

.partner__item-detail {
  background: transparent;
  border: none;
  color: $color-primary;
  font-size: 0.875rem;
  text-decoration: underline;
  cursor: pointer;
}

.partner__list-title {
  font-weight: $fontWeight-bold;
  font-size: 1.5rem;
}

.partner__list-results {
  @extend %listreset;
  margin: 30px 0;
}

.partner__item {
  border-bottom: 1px solid $color-light-grey-2;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.partner__item-tester {
  display: inline-block;
  background: $color-yellow;
  color: $color-primary;
  font-size: 0.8313rem;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.partner__item-meta {
  display: flex;
  gap: 30px;
  font-size: 0.875rem;
  margin-bottom: 15px;
}

.partner__item-name {
  font-size: 1.25rem;
  font-weight: $fontWeight-bold;
  margin-bottom: 10px;
}

.partner__item-address > * {
  display: inline-block;
}

.partner__item-zip-country {
  display: flex;
}

.partner__map-container {
  background: $color-light-grey;
  height: 600px;
}

.partner__detail-info {
  @extend %listreset;
  margin: 25px 0;
}

.partner__detail-item {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .icon {
    height: 20px;
    width: 20px;
    color: $color-white;
    margin-right: 10px;
  }

  a {
    color: $color-white;
  }
}

.partner__detail-dir {
  margin-top: 30px;
}

@media (min-width: $bp-sw-max) {
  .partner__group--form {
    display: flex;
    flex-wrap: wrap;

    .partner__group-label {
      width: 100%;
    }

    .partner__group-input {
      width: 80%;
    }

    .partner__finder-action {
      width: 20%;
      margin-top: 0;
    }

    .partner__finder-action-btn,
    .partner__finder-search-btn {
      width: auto;
    }
  }

  .partner__finder-action-btn {
    background: transparent;
    margin-left: 5px;
  }

  .partner__finder-search-btn {
    background: $color-yellow;

    .icon {
      fill: $color-primary;
    }

    &:hover,
    &:focus-visible {
      background: transparent;
      border: 1px solid $color-yellow;
      .icon {
        fill: $color-yellow;
      }
    }
  }
}

@media (min-width: $bp-mw) {
  .partner__finder {
    padding: 45px;
  }

  .partner__group-fieldset {
    display: flex;
    flex-flow: row wrap;
    gap: 48px;
  }

  .partner__finder-action--desktop {
    display: block;
  }

  .partner__list {
    padding: 30px 15px;
  }

  .partner__item {
    padding: 20px;
    margin-bottom: 0;
    &:hover,
    &.active {
      background: $color-light-grey;
    }
  }

  .partner__results {
    display: grid;
    grid-template-columns: 40% 60%;
  }

  .partner__detail {
    left: 40%;
    width: 350px;
  }
}

@media (min-width: $bp-l) {
  .partner__finder {
    padding: 80px;
  }

  .partner__results {
    display: grid;
    grid-template-columns: 30% 70%;
  }

  .partner__detail {
    left: 30%;
  }
}
