@use '../../abstracts' as *;


.products {
  @extend %listreset;
  margin-bottom: 30px;
}

.products__status {
  background: $color-yellow;
  border: 1px solid $color-black;
  font-size: 0.875rem;
  font-weight: $fontWeight-bold;
  padding: 5px 15px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.products__title {
  font-weight: $fontWeight-bold;
}

.products__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
}

.products__media {
  margin: 32px 0;
  text-align: center;
}

.product__image {
  object-fit: contain;
  height: 150px;
}

.products__action {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .button {
    padding: 12x 15px;
    width: 100%;
  }
}

/* Specification Table */

.products__info {
  display: none;
}

.products__table {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  gap: 16px 32px;
  @extend %listreset;
  margin-bottom: 28px;
}

.products__table-item {
  border-bottom: 1px solid $color-base;
  margin-bottom: 16px;
}

.products__table-label {
  display: flex;
  font-size: 0.8313rem;
}



.products__table-value {
  font-weight: $fontWeight-bold;
  font-size: 1.125rem;
  margin: 0;
}


@media(min-width: $bp-m) {
  .products {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 48px 16px;
  }

  .products__item {
    transition: 0.3s all linear;

    &:hover,
    &:focus-visible {
      background: $color-primary;

      .products__title,
      .products__specs {
        color: $color-white;
      }

      .products__table-item {
        border-color: $color-white;
      }

      .products__table-popover {
        color: $color-black;
      }

      .products__table-tooltip-btn {
        color: $color-white;
        border-color: $color-white;
      }

      .products__detail {
        background: $color-yellow;
        color: $color-primary;
      }

      .products__datasheet {
        background: $color-primary;
        border-color: $color-yellow;
        color: $color-yellow;
      }

      .icon {
        fill: $color-white;
      }
    }
  }

  .products__action {
    flex-wrap: nowrap;

    .button {
      width: 50%;
    }
  }
}

@media(min-width: $bp-l) {
  .products {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }
}