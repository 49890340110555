@use '../abstracts' as *;

.search-box {
    position:relative;
}

// autocomplete search results
.search-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 150;
}
.search-autocomplete__list {
    list-style: none;
    margin: 0;
    padding: 0;
    background: $color-primary;
    color: $color-white;
    border-radius: 5px;
}
.search-autocomplete__item {
    cursor: pointer;
}
.search-autocomplete__link {
    display: block;
    color: $color-white;
    text-decoration: none;
    padding: 5px 15px;
    &:hover,&:focus {
        text-decoration: underline;
    }
}

// search results

.search-results {
    margin: 2em 0;

    .card__meta {
        display: inline-block;
        background: $color-secondary;
        color: $color-primary;
        font-size: 0.875rem;
        font-weight: $fontWeight-semibold;
        padding: 0.2em 1em;
        letter-spacing: 1px;
        margin-bottom: 10px;
        border-radius: 2px;
        text-transform: uppercase;
    }
}

.search-results__title {
    display: block;
    color: $color-primary;
    font-weight: $fontWeight-medium;
    text-align: center;
}

.search-results__utility {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.search-results__sort {
    display: flex;
    align-items: center;
    gap: 0.5em;
    min-width: 250px;

    .search-results__sort-label {
        flex: 1 0 auto;
        font-weight: $fontWeight-bold;
    }

    select {
        padding: 0 15px;
        height: 40px;
    }
}

.search-results__count {
    color: $color-primary;
    font-weight: $fontWeight-bold;
    font-size: 1.5rem;
}

@media (min-width: $bp-m) {
    .search-results__utility {
        gap: 32px;
    }
}