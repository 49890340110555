@use '../../abstracts/' as *;

.product-series-tabs {

}

.product-series-tabs__list {
    @extend %listreset;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow: auto;
    width: 100%;
}

.product-series-tabs__tab {

}

.product-series-tabs__button {
    width: max-content;
    &.active {
        background: $color-dark-blue;
        color: $color-white;
        text-decoration: none;
        border-color: $color-dark-blue;
    }
}

.product-series-tabs__panel {
    display: none;
    margin: 0 0 40px;
    &.active {
        display: block;
    }
}

.product-series-tabs__content {
    padding: 90px 0;
}

.product-series-tabs__title {
    margin: 0 0 1.5em;   
}

.product-series-tabs__summary {
    font-size: 1.125rem;
}

// Product Series Items
.product-series {
    @extend %listreset;
    display: grid;
    gap: 40px;
}

.product-series__item {
}

.product-series__image {
    display: block;
    width: 100%;
    height: 280px;
    object-fit: cover;
    margin: 0 0 20px;
}

.product-series__title {

}

.product-series__summary {
    margin: 0 0 40px;
}

.product-series__link {

}

@media (min-width: $bp-l) {
    .product-series-tabs__panel {
        grid-template-columns: 400px 1fr;
        gap: 30px;
        padding: 80px 0;
        &.active {
            display: grid;
        }
    }

    .product-series-tabs__content {
        padding: 0;
    }

    .product-series-tabs__title {
        margin: 0 0 1em;
    }

    .product-series {
        display: grid;
        grid-template-columns: repeat(2, minmax(0,1fr));
        gap: 30px;
    }

    .product-series__image {
        aspect-ratio: 1;
        height: auto;
    }

}

@media (min-width: $bp-xxl) {
    .product-series {
        gap: 80px 50px;
    }
}