@use '../../abstracts/' as *;

//Standard Articles

.articles {
  list-style: none;
  margin: 40px 0;
  padding: 0;
}

.articles__item {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.articles__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  list-style: none;
  margin: 0 0 16px;
  padding: 0;
}

.articles__tags-item {
  display: inline-block;
  background: $color-light-grey;
  padding: 4px 16px;
  text-transform: uppercase;
}

.articles__title {
  margin-bottom: 16px;
}

.articles__summary {
  margin-bottom: 24px;
}

@media(min-width: $bp-mw) {
  .articles {
    display: grid;
    grid-template-columns: repeat(3, minmax(30%, 1fr));
    gap: 50px;
  }

  .articles__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .articles__title {
    line-height: 1.25;
  }

  .articles__action {
    margin-top: auto;
  }
}