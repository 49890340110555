
@use '../../abstracts/' as *;
//Grey Inlet Section

.grey-inlet__top {
  padding: 40px 0;
}

.grey-inlet__middle {
  background: $color-light-grey;
  padding: 32px 24px;
}

.grey-inlet__bottom {
  padding: 32px 0;
}

@media(min-width: $bp-mw) {
  .grey-inlet > .container {
    display: flex;
    flex-flow: row wrap;
    max-width: $grid-container-large;
    padding:0;
  }

  .grey-inlet__top {
    width: 100%;
    padding: 40px 24px;
  }

  .grey-inlet__middle {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    width: 75%;
    padding: 32px 24px;

  }

  .grey-inlet__bottom {
    width: 100%;
    padding: 32px 24px;
  }
}