@use "../abstracts/" as *;

body.modal-open {
  overflow: hidden;
}
.modal-dialog {
  position: fixed;
  z-index: 10;
  width: 100%;
  max-width: 1200px;
  max-height: 100%;
  top: 0;
  left: 0;
  border: 0;
  padding: 0;
  display: grid;
  place-items: center;
  grid-template-columns: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  padding: 15px;
  margin: auto;
  overflow: auto;

  &[open] {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  &::backdrop {
    background: rgba(0, 0, 0, 0.5);
  }
}

.modal-dialog-flyout-btn {
  background: transparent;
  border: none;
  color: $color-primary;
  cursor: pointer;
  font-weight: $fontWeight-semibold;
  text-transform: uppercase;
}

.modal-dialog--secondary {
  display: none;
  max-width: none;
  height: 100%;
  background: $color-primary;
  color: $color-white;
}

.modal-dialog--startstop {
  max-width: 90%;
  text-align: center;

  .modal-dialog__title {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
}

.modal-dialog--flyout {
  height: 100%;
  background: linear-gradient(260.85deg, #0d2739 0%, #153b59 100%),
    radial-gradient(
      31.63% 73.96% at 100% 100%,
      #214866 0%,
      rgba(33, 72, 102, 0) 100%
    ),
    radial-gradient(
      36.81% 88.31% at 68.65% 126.9%,
      rgba(26, 73, 125, 0.5) 0%,
      rgba(26, 73, 125, 0) 100%
    );
  color: $color-white;
  max-width: none;

  .modal-dialog__content {
    padding: 0;
  }

  .modal-dialog__close {
    &:before,
    &:after {
      background: $color-white;
    }
  }
}

.modal-dialog__close {
  @extend %buttonreset;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  color: $color-primary;
  z-index: 1;
  &::before,
  &::after {
    content: "";
    width: 22px;
    height: 2px;
    background: $color-primary;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    right: 0;
    left: 0;
    margin: auto;
  }
  &::after {
    transform: translateY(-50%) rotate(-45deg);
  }
  &:hover,
  &:focus-visible {
    outline: 2px solid $color-primary;
  }
}

.modal-dialog__content {
  width: 100%;
  padding: 50px 0 0;
  position: relative;
}

.modal-dialog__content--secondary {
  overflow: auto;
}

.modal-dialog__content--products {
  text-align: center;
}

@include breakpoint(l) {
  .modal-dialog {
    padding: 40px;
  }

  .modal-dialog--secondary {
    padding: 80px 50px 60px;
    background: linear-gradient(#0d2739, #153b59);
    &[open] {
      display: grid;
    }
  }

  .modal-dialog__close--secondary {
    top: 80px;
    right: 50px;
    &::before,
    &::after {
      background: $color-white;
      width: 24px;
      height: 3px;
    }
  }

  .modal-dialog__content--secondary {
    padding: 0;
    height: 100%;
  }

  .modal-dialog--startstop {
    max-width: 50%;
  }
}
