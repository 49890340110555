@use '../abstracts' as *;


.card {
    position:relative;
    padding: 1.5em;
    background:$color-white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    margin: 25px 0;
}

.card__header {
    display: flex;
    gap:1.25rem;
    align-items: center;
    flex-direction: column;
}

.card__title {
    @extend %item__title;
}

.card__date {
    @include x-rem(font-size, 18px);
}

.card.card--icon {
    display:flex;
    flex-direction: column;
    gap:0.625rem;
    padding:1.875rem 2.5rem 2.8125rem;

    &:after {
        display:none;
    }

    .card__title {
        @include x-rem(font-size, 25px);
    }

    .card__action {
        margin-top:auto;
    }

    .cards-list [class*="col-"] > & {
        position:relative;
        height:100%;
    }
}

.card.card--standard {
    border-radius: 0;
    padding:0;
    box-shadow: none;

    &:after {
        display:none;
    }
}

.card.card--counter {
    border-radius: 0;
    padding:0;
    box-shadow: none;

    &:after {
        display:none;
    }
}

@media(min-width:$bp-m){
    .card__header {
        flex-direction: row;
        flex-wrap: nowrap;
    }
}