@use "../../abstracts" as *;

.registration {
  background: radial-gradient(
      88.92% 45.59% at 68.65% 126.9%,
      rgba(26, 73, 125, 0.5) 0%,
      rgba(26, 73, 125, 0) 100%
    ),
    radial-gradient(
      73.96% 31.63% at 100% 100%,
      #214866 0%,
      rgba(33, 72, 102, 0) 100%
    ),
    linear-gradient(261deg, #0d2739 0%, #153b59 100%);
  color: $color-white;
  padding: 80px 0;

  h1,
  h2,
  h3,
  a,
  p {
    color: $color-white;
  }
}

.registration__step {
  display: none;

  &.active {
    display: block;
  }
}

.registration__form-back {
  margin: 15px 0;
}

.registration-input-info {
  margin-top: 10px;
  font-size: 0.8313rem;
}

.registration-form-msg {
  display: none;
  color: $color-red;
  font-size: 0.8313rem;
  margin-top: 10px;

  a {
    color: $color-red;
  }
}

.registration__form-label {
  display: block;
  color: $color-light-grey-2;
  font-size: 0.8313rem;
}

.registration__form-input {
  background: transparent;
  border: none;
  border-bottom: 1px solid $color-white;
  color: $color-white;
  height: 50px;
  width: 100%;

  &::placeholder {
    color: $color-white;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.registration__form-select {
  color: $color-white;
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  border-bottom: 1px solid $color-white;

  optgroup,
  option {
    color: $color-base;
  }
}

.registration__form-action {
  margin-top: 30px;
  text-align: right;
}

@media (min-width: $bp-mw) {
  .registration__wrapper {
    display: flex;
    column-gap: 40px;
  }

  .registration__wrapper-left {
    display: flex;
    flex-flow: column;
    width: 30%;
  }

  .registration__form-back {
    margin-top: auto;
    margin-bottom: 0;
  }

  .registration__wrapper-right {
    width: 70%;
  }

  .registration__form {
    display: flex;
    flex-flow: row wrap;
  }

  .registration__form-group--checkbox {
    flex: 1 100%;
  }
}
