@use '../abstracts' as *;

//Hubsport Forms
//Can be found on /contact-us or newsletters section on the homepage

.hs-form {
  .hs-form-field {
    margin: 10px 0;
  }

  .inputs-list {
    list-style: none;
    margin: 15px 0;
    padding: 0;
  }

  .hs-form-booleancheckbox-display {
    display: flex;
    align-items: center;
  }

  .hs-fieldtype-text, .hs-fieldtype-phonenumber, .hs-fieldtype-select {    
    label {
      display: block;
      font-size: 0.8313rem;
      margin-bottom: 6px;

      &.hs-error-msg {
        display: inline-block;
      }
    }

   .hs-input{
    border-color: $color-base;
    border-width:0 0 1px 0;
    border-radius: 0;
    padding: 5px 15px;
    height: 48px;
    width: 100%;

      &::placeholder {
        font-size: 0.8313rem;
      }
    }
  }

  .hs-fieldtype-textarea {
    border-color: $color-base;
    border-radius: 0;
    border-width:0 0 1px 0;

    label {
      display: block;
      font-size: 0.8313rem;
      margin-bottom: 6px;

      &.hs-error-msg {
        display: inline-block;
      }
    }

  }

  .hs-error-msg, .legal-consent-container .hs-error-msgs label {
    background: $color-yellow;
    color: $color-dark-blue;
    font-size: 0.875rem;
    padding: 10px 15px;
  }

  .hs-submit {
    margin-top: 30px;
  }

  .hs-button {
    border: none;
    background: $color-yellow;
    color: $color-dark-blue;
    cursor: pointer;
    padding: 12px 30px;
    text-transform: uppercase;

    &:hover, &:focus-visible {
      background: $color-blue;
      color: $color-white;
    }
  }
}