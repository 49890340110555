@use '../abstracts' as *;

$inputHeight: 52px !default;
$inputBg: #ffffff !default;
$inputBorderColor: #E3E3E3 !default;
$inputBorderWidth: 1px !default;
$inputBorderRadius: 5px !default;
$inputBoxShadow: inset 0 1px 5px rgba(0, 0, 0, 0.22) !default;
$inputBoxShadowActive: inset 0 1px 5px rgba(0, 0, 0, 0.22), inset 0 0 0 2px $color-primary !default;


/* ================================================================
   Wraps
   ================================================================ */

.form,
form {
    margin: 0.9375rem 0;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group--end {
    display: flex;
    justify-content: flex-end;
}


/* ================================================================
   Labels/Indicators
   ================================================================ */

.form-control-label {
    display: block;
    font-size: .75rem;
    margin: 0 0 .5em;
}

.required-indicator {
    position: relative;
    padding-left: 10px;

    &:before {
        display: block;
        content: '*';
        position: absolute;
        left: 0;
        top: 0;
        color: red;
    }
}

.error-message {
    margin-top: 5px;
    font-size: 1em;
    color: red;
}



/* ================================================================
   Controls/Inputs
   ================================================================ */

.form-control {
    width: 100%;
	@include x-rem(height, $inputHeight);
	padding: 5px 15px;
	border: $inputBorderWidth solid $inputBorderColor;
	border-radius: $inputBorderRadius;
	box-shadow: none;
    padding-left: 1.25rem;
    padding-right:1.25rem;
	background: $inputBg;
	color: $color-black;
	font-size: 1rem;
	will-change: border-color;
	transition: border-color $nav-transition-speed ease-in-out, box-shadow $nav-transition-speed ease-in-out;

	&::placeholder {
		color: $color-black;
		font-size: .8rem;
	}

	&:focus {
		border-color: $color-primary;
		outline: 0;
	}
}

.form-control--white {
  background: transparent;
  border: 0;
  border-bottom: 1px solid $color-white;
  border-radius: 0;
  height: 40px;
  color: $color-white;
  width: 100%;
}

.form-legend {

}

.form-select-wrapper {
    position: relative;
    &::after {
        content: '';
        width: 12px;
        height: 12px;
        border-right: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
        position: absolute;
        top: 50%;
        transform: rotate(45deg);
        right: 10px;
        z-index: 1;
        pointer-events: none;
    }
}

.form-select {
    padding: 10px 20px 10px 10px;
    width: 100%;
    appearance: none;
    border: 0;
    font-weight: $fontWeight-bold;
    background: none;
}

.form-select--white {
    color: $color-white;
    background: $color-primary;
    border-bottom: 1px solid $color-white;

    option {
      padding: 1em 0;
    }
}

.form-select--yellow {
  color: $color-yellow;
  background: transparent;
  border-bottom: 1px solid $color-yellow;
}

.form-textarea {
    @extend .form-control;
	padding: 1rem 1em;
	min-height: 100px;
}

.radio-item {}

.radio-item__label {}

.radio-item__input {
}

.checkbox-item {}

.checkbox-item__label {}

.checkbox-item__input {
}


[data-sf-role="error-message"] {
    margin-top:1rem;
    color:red;

    &:empty {
        display: none;
    }
}

[data-sf-role="form-container"] .row {
    row-gap: 0;
}

.hinted-input {
    position: relative;
    overflow: hidden;

    .form-control {
        padding-top: 1rem;

        & ~ label {
            position: absolute;
            top: 50%;
            left: 1.25rem;
            margin: 0;
            opacity: 1;
            font-size: 1rem;
            cursor: text;
            transform:translateY(-50%);
            will-change: transform, font-size;
            transition: transform 0.3s ease-in-out, font-size .3s ease-in-out, top .3s ease-in-out;
        }

        &:focus ~ label,
        &[required]:valid ~ label,
        &.valid ~ label,
        &.hint ~ label,
        &:-webkit-autofill ~ label {
            top: 1rem;
            @include x-rem(font-size, 12px);
        }

        &[required] ~ label:after {
            content: '*';
            color: red;
        }
    }

    textarea.form-control ~ label {
        top:.5rem;
    }

    .form-control.hint ~ label {
        top: 1rem;
    }

    textarea.form-control {
        padding-top: 2.2rem;

        & ~ label {
            top:1.25rem;
        }
    }
}