@use '../../abstracts/' as *;@use '../../abstracts/' as *;

.language-selector-dialog {
}

.language-selector-dialog__list {
    @extend %listreset;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    &[hidden] {
        display: none;
    }
}

.language-selector-dialog__item {
    width: 100%;
}

.language-selector-dialog__button {
    @extend %buttonreset;
    color: $color-white;
    &.active {
        color: $color-yellow;
        font-weight: $fontWeight-bold;
    }
}


.language-selector-dialog__title {
    width: 100%;
    text-align: left;
}

.language-selector-dialog__form--desktop {
    display: none;
}


@media (min-width: $bp-l) {
    .language-selector-dialog {
        display: flex;
        flex-flow: column;
        align-items: center;
        height: 100%;
        .form-group {
            margin: auto 0 0;
            text-align: right;
        }
    }

    .language-selector-dialog__title {
        margin: 0 0 100px;
    }

    .language-selector-dialog__form {
        display: none;
    }

    .language-selector-dialog__form--desktop {
        display: block;
        width: 65%;
    }

    .language-selector-dialog__list {
        width: 210px;
    }

    .language-selector-dialog__country {
        width: 100%;
        color: $color-secondary;
    }

    .language-selector-dialog__grid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

}