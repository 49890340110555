@use "../abstracts" as *;

%button {
  display: inline-block;
  padding: 12px 30px;
  border: 1px solid $color-primary;
  border-radius: 0;
  font-family: var(--font-primary);
  font-weight: $fontWeight-normal;
  letter-spacing: 1px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  background: $color-primary;
  color: $color-white;
  cursor: pointer;
  will-change: background;
  transition: background $nav-transition-speed ease-out;

  &[disabled] {
    opacity: 0.5;
  }
}

button {
  &[disabled] {
    cursor: not-allowed;
  }
}

.button {
  @extend %button;
  &:hover,
  &:focus-visible {
    text-decoration: underline;
    background: transparent;
    border-color: $color-primary;
    color: $color-primary;
  }
}

.button--secondary {
  background: transparent;
  border: 1px solid $color-primary;
  color: $color-primary;

  &:hover,
  &:focus-visible {
    background: $color-primary;
    border-color: $color-primary;
    color: $color-white;
  }
}

.button--icon {
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;

  svg {
    width: 15px;
    height: 15px;
    fill: $color-white;
  }

  &:hover,&:focus-visible {
    svg {
      fill: $color-primary;
    }
  }
}

.button--full {
  width: 100%;
}

.button--full-mobile {
  width: 100%;
}

.button--yellow,
.button--yellow-arrow-up {
  background: $color-yellow;
  border-color: $color-yellow;
  color: $color-primary;

  svg {
    fill: $color-base;
  }

  &:hover,
  &:focus-visible {
    background: $color-primary;
    border-color: $color-primary;
    color: $color-white;

    svg {
      fill: $color-white;
    }
  }

  &[target="_blank"] {
    &:after {
      display: inline-block;
      content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><title>arrow-up</title><path fill="%230a2336" d="M5.625 3.375v1.125h7.082l-9.332 9.332 0.793 0.793 9.332-9.332v7.082h1.125v-9h-9z"></path></svg>');
      height: 24px;
      margin-left: 5px;
      vertical-align: middle;
    }

    &:hover,
    &:focus-visible {
      &:after {
        animation: fadeInUp 0.3s ease-in;
        content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><title>arrow-up</title><path fill="%23ffffff" d="M5.625 3.375v1.125h7.082l-9.332 9.332 0.793 0.793 9.332-9.332v7.082h1.125v-9h-9z"></path></svg>');
      }
    }
  }
}

.button--yellow-outline {
  background: transparent;
  border-color: $color-yellow;
  color: $color-yellow;

  &:hover,
  &:focus-visible {
    background: $color-yellow;
    border-color: $color-yellow;
    color: $color-primary;
  }

  &[target="_blank"] {
    &:after {
      display: inline-block;
      content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><title>arrow-up</title><path fill="%23FFDD00" d="M5.625 3.375v1.125h7.082l-9.332 9.332 0.793 0.793 9.332-9.332v7.082h1.125v-9h-9z"></path></svg>');
      height: 24px;
      margin-left: 5px;
      vertical-align: middle;
    }

    &:hover,
    &:focus-visible {
      &:after {
        animation: fadeInUp 0.3s ease-in;
        content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><title>arrow-up</title><path fill="%23143854" d="M5.625 3.375v1.125h7.082l-9.332 9.332 0.793 0.793 9.332-9.332v7.082h1.125v-9h-9z"></path></svg>');
      }
    }
  }
}

.button--yellow-arrow-up {
  &:after {
    content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><title>arrow-up</title><path fill="%230a2336" d="M5.625 3.375v1.125h7.082l-9.332 9.332 0.793 0.793 9.332-9.332v7.082h1.125v-9h-9z"></path></svg>');
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
    transition: 0.2s margin ease-in;
  }

  &:hover,
  &:focus-visible {
    &:after {
      content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><title>arrow-up</title><path fill="%23FFDD00" d="M5.625 3.375v1.125h7.082l-9.332 9.332 0.793 0.793 9.332-9.332v7.082h1.125v-9h-9z"></path></svg>');

      animation: fadeInUp 0.3s ease-in;
    }
  }
}

.button--arrow-up {
  &:after {
    content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><title>arrow-up</title><path fill="%23FFDD00" d="M5.625 3.375v1.125h7.082l-9.332 9.332 0.793 0.793 9.332-9.332v7.082h1.125v-9h-9z"></path></svg>');
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
    transition: 0.2s margin ease-in;
  }

  &:hover,
  &:focus-visible {
    &:after {
      content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><title>arrow-up</title><path fill="%230a2336" d="M5.625 3.375v1.125h7.082l-9.332 9.332 0.793 0.793 9.332-9.332v7.082h1.125v-9h-9z"></path></svg>');
      animation: fadeInUp 0.3s ease-in;
    }
  }
}

.button--white {
  background: $color-white;
  border-color: $color-white;
  color: $color-primary;

  &:hover,
  &:focus-visible {
    background: $color-primary;
    border-color: $color-primary;
    color: $color-white;
  }
}

.button--small {
  padding: 0.5em 0.5em 0.35em;
  border-radius: 24px;
  border: 1px solid $color-dark-blue;
  background: $color-white;
  color: $color-dark-blue;
  font-size: 0.75rem;

  &:hover,
  &:focus-visible {
    background: $color-dark-blue;
    color: $color-white;
    text-decoration: none;
    border-color: $color-dark-blue;
  }
}

.button--inline {
  width: auto;
}

.button--yellow-link {
  background: transparent;
  border: none;
  color: $color-secondary;

  &:hover, &:focus-visible {
    text-decoration: underline;
    color: $color-white;
  }
}

@media (min-width: $bp-l) {
  .button--icon {
    width: auto;
  }

  .button--full-mobile {
    width: auto;
  }

  .button--small {
    font-size: 0.9375rem;
    padding: 0.5em 1em;
    border: 2px solid $color-primary;
  }
}
