@use '../../abstracts/' as *;


//Landscape light

.landscape-callout__item {
  margin-bottom: 40px;
  padding-bottom: 30px;
  border-bottom: 1px solid $color-white;

  &:last-child {
    margin-bottom: 0;
  }
}

.landscape-callout__title {
  margin: 24px 0;
}

.landscape-callout__img {
  max-height: 72px;
}

.landscape-callout__action {
  margin-top: 35px;
}

@media(min-width: $bp-l) {
  .landscape-callout__item {
    display: flex;
    flex-flow: row wrap;
  }

  .landscape-callout__media {
    flex: 1 100%;
    margin-bottom: 24px;
  }

  .landscape-callout__title {
    flex: 1 40%;
    margin: 0;
    line-height: 1.5;
  }

  .landscape-callout__content {
    flex: 1 60%;
    padding-left: 30px;
  }

  .landscape-callout__description {
    font-size: 1.25rem;
  }
}

//Landscape Light
.landscape-callout--light {
  .landscape-callout__content {
    color: $color-white;
  }
  
  .landscape-callout__title {
    color: $color-white;
  }
  
}

//Landscape Dark

.landscape-callout--dark {
  .landscape-callout__media {
    text-align: center;
  }

  .landscape-callout__title, .landscape-callout__content {
    color: $color-base;
  }
}

@media(min-width: $bp-l) {
  .landscape-callout--dark {
    .landscape-callout__media {
      flex: 1 15%;
    }

    .landscape-callout__content {
      flex: 1 85%;
    }
  }
}