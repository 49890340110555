@use '../../abstracts/' as *;

//Color Sections

.color-section {
  background: $color-dark-blue;
  padding: 80px 0;
}

.color-section__left {
  background: $color-primary;
  padding: 32px 56px;
}

.color-section__right {
  padding: 30px 56px;
}

.color-section--dark-blue {
  background: $color-primary;

  .color-section__left {
    background: $color-dark-blue;
  }
}

.color-section--white {
  background: $color-primary;
  padding: 0;

  .color-section__left {
    background: $color-white;
  }

  .color-section__right {
    color: $color-white;
  }
}

@media(min-width: $bp-mw) {
  .color-section > .container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: flex-start;
    column-gap: 56px;
    max-width: $grid-container-large;
    padding: 0;
  }

  .color-section--white {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

}

@media(min-width: $bp-xl) {
  .color-section__left {
    padding: 32px 56px;
  }

  .color-section__right {
    padding: 32px 56px 32px 0;
   }

   .color-section--white .color-section__right {
    padding: 32px 56px;
   }
}