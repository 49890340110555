@use "../abstracts" as *;

.breadcrumbs__list {
  @extend %listreset;
  display: flex;
  gap: 0.5rem;
  margin: 1rem 0;
}

.breadcrumbs__item {
  display: inline-flex;
  gap: 0.5rem;
  font-size: 1.125rem;
  position: relative;

  &:after {
    content: "/";
    display: block;
  }

  &:first-child a {
    text-decoration: underline;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  &.active {
    font-weight: $fontWeight-bold;
  }
}

.breadcrumbs__item-link {
  font-weight: $fontWeight-normal;
  text-decoration: none;
}

.breadcrumbs--light {
  color: $color-white;

  .breadcrumbs__item-link {
    color: $color-white;
  }
}
