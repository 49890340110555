@use '../../abstracts/' as *;

.varta-video {

}

.varta-video__heading {
}

.varta-video__content {
    font-size: 1.125rem;
    margin: 0 0 60px;
}

.varta-video__button {

}

.varta-video__embed {
    display: grid;
    place-items: center;
    iframe {
        width: 100%;
    }
}

@media (min-width: $bp-l) {
    .varta-video__content {
        font-size: 1.25rem;
        margin: 0 0 120px;
    }
}