@use '../../abstracts/' as *;

.language-selector {
}

.language-selector__toggle {
    @extend %buttonreset;
    display: none;
    position: relative;
}

.language-selector__icon {
    width: 14px;
    height: 14px;
    fill: $color-white;
}

.language-selector__arrow {
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    border-right: 2px solid $color-white;
    border-bottom: 2px solid $color-white;
}

.language-selector__flag {
    width: 20px;
}


@media (min-width: $bp-l) {
    .language-selector {
        margin-right: auto;
    }

    .language-selector__form {
        display: none;
    }

    .language-selector__toggle {
        display: grid;
        grid-template-columns: 14px 14px 20px;
        gap: 10px;
        align-items: center;
    }
}