@use "../../abstracts" as *;

.registration__business-section {
  margin-bottom: 50px;
}

.registration__form-title {
  margin-bottom: 40px;
}

.registration__form-group {
  margin-bottom: 10px;
}

.registration__form-businesses {
  display: flex;
  flex-flow: row wrap;
  gap: 15px;
  background: $color-navy;
  padding: 25px;
  margin-top: 30px;

  .business {
    flex: 1 100%;
  }
}

.registration__panel {
  display: none;
  margin-top: 40px;
}

.business__name {
  font-size: 1.25rem;
  font-weight: $fontWeight-bold;
}

@media(min-width: $bp-m) {
  .registration__form-businesses {
    .business {
      flex: 1 48%;
    }
  }
  
}