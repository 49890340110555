@use '../../abstracts/' as *;

.image-carousel {
    display: grid;
    place-items: center;
    position: relative;
    padding: 100px 0;
}

.image-carousel__list {
    @extend %listreset;
}

.image-carousel__figure {
    margin: 0;
    width: 100%;
    height: 380px;
    background: $color-light-grey;
}

.image-carousel__image {
    display: block;
    margin: 0 auto;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.image-carousel__image--featured {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.image-carousel__button {
    width: auto;
    position: relative;
}

.image-carousel__heading {

}

.image-carousel__summary {
    margin: 0 0 40px;
}

.image-carousel__controls {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 40px 0 0;
}

.image-carousel__arrows {
    width: 100px;
    display: flex;
}

@media (min-width: $bp-l) {
    .image-carousel {
        padding: 180px 0;
    }
    
    .image-carousel__summary {
        font-size: 1.25rem;
    }
}