@use "../../abstracts/" as *;

.heavy-commercial {
  display: none;
}

.heavy-commercial__question {
  margin: 30px 0;
}


.heavy-commercial__legend {
  font-weight: $fontWeight-bold;
  font-size: 1.25rem;
  margin-bottom: 30px;
}

.heavy-commercial__input {
  cursor: pointer;
  appearance: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.heavy-commercial__question--image {
  .heavy-commercial__label {
    display: block;
    font-weight: $fontWeight-bold;
    padding: 30px;
    position: relative;
    text-align: center;
    cursor: pointer;
  }

  .heavy-commercial__input {
    &:checked {
      &:before {
        content: "";
        background: $color-blue-highlight;
        border: 2px solid $color-blue;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;   
        z-index: -1;
      }      
    }

  }

  .heavy-commercial__img {
    display: block;
    max-width: 500px;
    position: relative;
  }
}

.heavy-commercial__question--range {
  .heavy-commercial__group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 50px 0;
    position: relative;

    &:before {
      background: $color-light-grey-2;
      content: "";
      height: 5px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    }
  }

  .heavy-commercial__sub {
    position: relative;

    .heavy-commercial__label {
      background: $color-light-grey-2;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      text-align: center;

      .heavy-commercial__label-text {
        position: absolute;
        top: -30px;
      }
    }

    .heavy-commercial__input {    
      &:checked:after {
        background-image: url("/dist/images/hcv/cloud-moon-icon.png");
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 50%;
        display: block;
        content: "";
        height: 50px;
        width: 50px;
      }
    }
  }

  .heavy-commercial__input--distance {
    .heavy-commercial__input:checked:after {
      background-image: url("/dist/images/hcv/pins-icon.png");
    }
  }
}

.heavy-commercial__question--tile {
  .heavy-commercial__group {
    margin: 30px 0;
  }

  .heavy-commercial__sub {
    position: relative;
    text-align: center;
    margin-bottom: 15px;

    .heavy-commercial__label {
      background: $color-light-grey;
      color: $color-primary;
      cursor: pointer;
      display: block;
      font-weight: $fontWeight-bold;
      height: 100%;
      padding: 30px 15px;
      position: relative;

      .heavy-commercial__label-text {
        margin-top: 10px;
        position: relative;
      }

      .heavy-commercial__img {
        height: auto;
        width: 50px;
      }
    }

    .heavy-commercial__input[type="checkbox"] {
      &:checked::after {
        content: "";
        background: $color-blue;
        opacity: 0.3;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    .icon {
      fill: $color-primary;
      height: 50px;
      width: 50px;
      position: relative;
    }
  }
}

.heavy-commercial__submit {
  margin-bottom: 45px;
}

@media (min-width: $bp-sw) {
  .heavy-commercial__question--image {
    .heavy-commercial__fieldset {
      display: flex;
      gap: 50px;
    }
  }

  .heavy-commercial__question--tile {
    .heavy-commercial__group {
      display: grid;
      grid-template-columns: repeat(2, minmax(150px, 1fr));
      gap: 25px;
    }
    .heavy-commercial__input {
      margin-bottom: 0;
    }
  }
}

@media (min-width: $bp-m) {
  .heavy-commercial__question--tile {
    .heavy-commercial__group {
      grid-template-columns: repeat(4, minmax(150px, 1fr));
    }
  }
}

@media (min-width: $bp-l) {
  .heavy-commercial__question--tile {
    .heavy-commercial__group {
      grid-template-columns: repeat(6, minmax(100px, 1fr));
    }
  }
}
