@use '../../abstracts' as *;

.hero {
  background: linear-gradient(260.85deg, #0D2739 0%, #153B59 100%),
  radial-gradient(31.63% 73.96% at 100% 100%, #214866 0%, rgba(33, 72, 102, 0) 100%),
  radial-gradient(36.81% 88.31% at 68.65% 126.9%, rgba(26, 73, 125, 0.5) 0%, rgba(26, 73, 125, 0) 100%);  
  padding: 40px 24px;
}

.hero__content, .hero__title {
  color: $color-white;
}

.hero__summary {
  line-height: 24px;
}

.hero__action {
  margin: 24px 0;
}

.hero__media {
  position: relative;
  min-height: 300px;
}

.hero__img {
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@media(min-width: $bp-m) {
  .hero__media {
    height: 400px;
  }
}

@media(min-width: $bp-mw) {
.hero {
  padding: 80px 56px 0 56px;
}

  .hero__content {
    display: flex;
    flex-flow: row wrap;
  }

  .hero__media, .hero__content-inner {
    flex: 1 50%;
  }

  .hero__media {
    order: 1;
  }

  .hero__content-inner {
    order: 2;
    padding: 0 30px;
  }
}

@media(min-width: $bp-l) {
  .hero__media {
    height: 288px;
  }

  .hero__content {
    width: 70%;
    margin-left: auto;
    margin-top: 100px;
  }

  .hero__content-inner {
    padding: 0 45px;
  }
}