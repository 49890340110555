@use '../abstracts' as *;

.pagination {
	@extend %listreset;
	display: flex;
    flex-wrap:wrap;
    justify-content:center;
    gap:.5rem;
}

.page-link {
    display:block;
    border-radius: 5px;
    border:1px solid #ccc;
    padding:5px 15px;
    transition:background .3s ease-in-out, color .3s ease-in-out;

    &:hover, &:focus, .active & {
        background:$color-primary;
        text-decoration: none;
        color:$color-white;
    }

    .active & {
        &:hover, &:focus {
            text-decoration:none;
            cursor:default;
        }
    }
}