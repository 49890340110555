@use '../abstracts' as *;

/* Maps & Variables
================================================================ */
$textAlignments:left, right, center,justify;

$textSizes: "small" 1rem 1.125rem, "medium" 1rem 1.25rem,
  "large" 1.125rem 1.25rem;

$fontWeights: (
    "normal": $fontWeight-normal,
    "medium": $fontWeight-medium,
    "semibold": $fontWeight-semibold,
    "bold": $fontWeight-bold
);

$fonts: (
    "primary": var(--font-primary),
    "secondary": var(--font-secondary),
);

$colors:(
    "primary":$color-primary,
    "secondary":$color-secondary,
    "black":$color-black,
    "white":$color-white
);

$textCase:(
    "upper":uppercase,
    "lower":lowercase,
    "cap":capitalized
);

$sides: (
    all:"",
    vertical:"y",
    horizontal:"x",
    top:"t", 
    right:"r", 
    bottom:"b", 
    left:"l"
);

$spacer: 1rem;
$numOfSpacers:32;

$widths: (
    "auto":auto,
    "sm": 25%,
    "md": 50%,
    "lg": 75%,
    "full": 100%
);

$grids: (
    "2x2": 2,
    "3x3": 3,
    "4x4": 4
);

/* Mixins & Functions
================================================================ */
@mixin generate-text-alignment-classes($bpAbbv: '') {
    @each $alignment in $textAlignments {
        .text#{$bpAbbv}-#{$alignment} {
            text-align: $alignment;
        }
    }
}

@mixin generate-grid-classes($bpAbbv: ''){
    @each $grid, $value in $grids {
        .grid#{$bpAbbv}-#{$grid} {
            grid-template-columns: repeat($value, minmax(0px, 1fr));
        }
    
        .grid#{$bpAbbv}-#{$grid}-auto {
            grid-template-columns: repeat($value, minmax(auto, auto));
        }
    }
}

@function spacer-value($multiplier) {
    @return $spacer * $multiplier;
}

@mixin generate-spacing-classes($bpAbbv: ''){
    @each $side, $sideValue in $sides {
        @for $i from 0 through $numOfSpacers {
            $spaceValue: spacer-value($i * 0.25);
        
            @if ($sideValue == "") {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin: $spaceValue;
                }
            } @else if ($sideValue == "y") {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding-top: $spaceValue;
                    padding-bottom: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin-top: $spaceValue;
                    margin-bottom: $spaceValue;
                }
            } @else if ($sideValue == "x") {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding-left: $spaceValue;
                    padding-right: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin-left: $spaceValue;
                    margin-right: $spaceValue;
                }
            } @else {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding-#{$side}: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin-#{$side}: $spaceValue;
                }
            }
        }
    }
}

/* Text Helpers
================================================================ */
@include generate-text-alignment-classes();

@each $bpAbbv, $breakpoint in $fontBreakpoints {
    // .text-size-large { font-size: 1rem }
    @media (min-width: $breakpoint) {
      @each $name, $mobileSize, $desktopSize in $textSizes {
        .text-size-#{$name} {
          @if ($breakpoint == $bp-l) {
            font-size: $desktopSize;
          } @else {
            font-size: $mobileSize;
          }
        }
      }
    }
  }

@each $fontWeight, $weightValue in $fontWeights {
    // .text-normal { font-weight:normal; }
    .text-#{$fontWeight} {
        font-weight:$weightValue;
    }
}

@each $case, $caseValue in $textCase {
    // .text-upper { text-transform:uppercase; }
    .text-#{$case} {
        text-transform:$caseValue;
    }
}

@each $color, $colorValue in $colors {
    // .color-primary { color:$color-primary }
    .color-#{$color} {
        color:$colorValue;
    }
}

@each $font, $fontValue in $fonts {
    // .font-primary { font-family:$font-primary }
    .font-#{$font} {
        font-family:$fontValue;
    }
}

@each $width, $widthValue in $widths {
    // .w-3 { width:25% }
    .w-#{$width} {
        width:$widthValue;
    }
}

/* Layout Helpers
================================================================ */
[class*="grid-"] {
    display: grid;
    gap:calc($grid-gutter-em * 2);

    &:is(ul) {
        @extend %listreset;
    }
}

@include generate-grid-classes();

/* Element Helpers
================================================================ */
.clearfix {
    @extend %clearfix;
}

@include generate-spacing-classes();

/* Media Queries
================================================================ */
@each $bpAbbv, $bp in $breakpoints {
    @media(min-width:$bp){
        @include generate-grid-classes('-#{$bpAbbv}');
        @include generate-text-alignment-classes('-#{$bpAbbv}');
        @include generate-spacing-classes('#{$bpAbbv}-');
    }
}