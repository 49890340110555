@use "../../abstracts" as *;

.battery-results {
  margin: 40px 0;
}

.battery-results__vehicle {
  color: $color-primary;
  font-size: 1.25rem;
  font-weight: $fontWeight-bold;
}

.battery-results__vehicle-code, .battery-results__vehicle-message {
  display: none;
  font-weight: $fontWeight-bold;
}

.battery-results__start {
  display: none;
  margin: 80px 0 150px 0;
  h3 {
    margin-bottom: 30px;
  }
  .battery-results__start-container {
    background: $color-light-grey;
    padding: 32px 40px;
    width: 60%;

    h4 {
      font-size: 1.5rem;
      font-weight: $fontWeight-normal;
      margin-bottom: 20px;
    }
  }
}

.battery-results__vehicle-tecdoc {
  display: none;
}

.battery-finder__notes {
  background: $color-light-grey;
  padding: 15px;
  margin-top: 20px;
}

.battery-results__item {
  padding: 32px;
  transition: 0.3s background ease-in;
  &:hover,
  &:focus-visible,
  &.active {
    background: $color-dark-blue;
    color: $color-white;

    .battery-results__status {
      color: $color-dark-blue;
    }

    .battery-results__table-item {
      border-color: $color-white;
    }

    .tooltip-btn__icon--results {
      fill: $color-white;
    }

    .battery-results__datasheet-link {
      border: 1px solid $color-yellow;
      background: transparent;
      color: $color-yellow;

      .icon {
        fill: $color-yellow;
      }
    }

    .battery-results__item-link {
      background: $color-yellow;
      color: $color-dark-blue;
      .icon {
        fill: $color-dark-blue;
      }
    }
  }
}

.battery-results__status {
  background: $color-yellow;
  border: 1px solid $color-black;
  font-size: 0.875rem;
  font-weight: $fontWeight-bold;
  padding: 5px 15px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.battery-results__title {
  font-weight: $fontWeight-normal;
  margin-bottom: 40px;
}

.battery-results__item-title {
  font-size: 1.5rem;
  font-weight: $fontWeight-bold;
}

.battery-results__item-media {
  position: relative;
  height: 250px;
  margin: 20px 0;

  .battery-results__img {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.battery-results__datasheet-link,
.battery-results__item-link {
  display: block;

  .icon {
    height: 18px;
    width: 18px;
    margin-left: 5px;
  }
}

.battery-results__list {
  @extend %listreset;
}

.battery-results__table {
  @extend %listreset;
  margin: 30px 0;
}

.battery-results__table-item {
  border-bottom: 1px solid $color-black;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.battery-results__table-label {
  display: flex;
  align-items: center;
  font-size: 0.8313rem;
}

.battery-results__table-value {
  font-weight: $fontWeight-bold;
  margin: 0;
}

.battery-results__msgs {
  display: none;
  font-weight: $fontWeight-bold;
  padding: 30px 0;
  text-align: center;
  &.active {
    display: block;
  }
}

@media (min-width: $bp-m) {
  .battery-results__list {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    gap: 30px;
    margin-bottom: 80px;
  }
}

@media (min-width: $bp-l) {
  .battery-results__list {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }
}
