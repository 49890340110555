@use '../abstracts' as *;

.search-toggle {
    display: inline-block;
    background: $color-primary;
    border: none;
    height: 100%;
    padding: 16px 19px;
    cursor: pointer;

    &.active {
        .icon-search {
            display: none;
        }

        .icon-close {
            display: block;
        }
    }

    .icon-close {
        display: none;
    }


    svg {
        width: 26px;
        height: 26px;
        fill: $color-white;
    }
}

.site-search {
    display: none;
    position: absolute;
    box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.2);
    right: 0;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 99;
}

.site-search__controls {
    display: flex;

    .site-search__input {
        background: $color-light-grey;
        border-radius: 0;
        border: none;
        color: $color-dark-blue;
    }

    .site-search__submit {
        background: $color-light-grey;
        border: none;
        cursor: pointer;
        width: 50px;

        svg {
            height: 18px;
            width: 18px;
            fill: $color-dark-blue;
        }
    }
}

//Desktop search toggle hidden on mobile
.header-container__search .search-toggle {
  display: none;
}

@media(min-width: $bp-l) {
    .site-search {
        max-width: 1400px;
        margin: 0 auto;
    }

    .search-toggle {
        background: $color-white;
        border: 1px solid $color-primary;
        padding: 8px 11px;
        svg {
            height: 20px;
            width: 20px;
            fill: $color-primary;
        }
    }

    //Mobile toggle hidden on desktop
    //Located in Base Template: Header Footer in Embed Code widget
    .header-container__nav .search-toggle {
      display: none;
    }

    .header-container__search .search-toggle {
      display: inline-block;
    }
    
}