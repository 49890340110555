@use '../../../node_modules/tiny-slider/dist/tiny-slider.css';

@use '../abstracts/' as *;

.tiny-slider__arrow {
    @extend %buttonreset;
    width: 48px;
    height: 48px;
    display: grid;
    place-items: center;
    background: $color-primary;
}

.tiny-slider__arrow--next {

}

.tiny-slider__arrow--previous {

}

.tiny-slider__icon {
    width: 22px;
    height: 22px;
    fill: $color-white;
}

.tiny-slider__counter {
    margin: 0 30px 0 0;
    span {
        font-size: 1.25rem;
        color: $color-black;
    }
    span:nth-child(2) {
        color: $color-med-grey;
    }
}