@use '../abstracts/' as *;

.error-page {
    height: 100%;
}

.error-page__header {
    background: $color-white;
    padding: 20px 15px;
}

.error-page__content {
    max-width: $grid-container-small;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    color: $color-white;
}

.error-page__link {
    display: inline-block;
}

.error-page__logo {
    width: 250px;
}

.error-page__title {
    width: 100%;
}

.error-page__message {
    width: 100%;
}

.error-page__button {

}