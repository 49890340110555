@use "../abstracts" as *;

.tooltip {
  position: relative;
}

.tooltip__btn {
  display: inline-block;
  background: transparent;
  border: none;
  color: $color-primary;
  cursor: pointer;
  margin-left: 3px;
  padding: 0;
}

.tooltip__btn-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
}

.tooltip__popover {
  background: $color-white;
  border: 1px solid $color-med-grey;
  color: $color-base;
  display: none;
  font-size: 0.8313rem;
  position: absolute;
  left: 10%;
  padding: 15px;
  z-index: 1;
  transform: translateX(-10%);
  width: 200px;
}

//Overwrites for Product Spec Tooltips
.products__table-item:nth-child(odd),
.products-detail__item:nth-child(odd) {
  .tooltip__popover {
    left: auto;
    right: 100%;
    transform: translateX(100%);
  }
}

.tooltip__popover--dropdown {
  @extend %listreset;
  left: 0;
  padding: 0;
  transform: none;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
  z-index: 2;  
}

@media (min-width: $bp-m) {
  .products__item {
    &:hover,
    &:focus-visible {
      .tooltip__btn-icon {
        fill: $color-white;
      }
    }
  }
}
