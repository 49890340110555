@use '../../abstracts/' as *;

.article-detail {
  margin-top: 40px;
  margin-bottom: 120px;
}

.article-detail__tags {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 25px;
  padding: 0;
  gap: 8px;
}

.article-detail__item {
  padding: 4px 16px;
  border: 1px solid $color-base;
  text-transform: uppercase;
}

.article-detail__summary {
  font-size: 1.25rem;
  margin-top: 25px;
}

.article-detail__media {
  position: relative;
  height: 420px;
  margin-bottom: 60px;
  margin-top: 40px
}

.article-detail__img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.article-detail__content {
  font-size: 1.25rem;
  max-width: 55em;
  margin: 0 auto;
  p {
    margin-bottom: 25px;
  }
}