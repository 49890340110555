@use "../abstracts" as *;

.site-footer {
  background: $color-primary;
  color: $color-white;
  padding: 80px 0 15px 0;
}

.footer-container {
  max-width: 90em;
}

.footer-main a {
  color: $color-secondary;
}

.footer-utility {
  margin: 15px 0;
  border-top: 1px solid $color-white;
  border-bottom: 1px solid $color-white;
  padding: 30px 0;

  ul {
    margin: 0;

    li {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.footer-logo {
  margin: 30px 0;

  img {
    max-width: 200px;
  }
}

.social {
  @extend %listreset;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 1em;
}

.social__link {
  align-items: center;
  border: 2px solid $color-white;
  border-radius: 50%;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
  text-align: center;

  .icon {
    fill: $color-white;
    height: 16px;
    width: 16px;
  }
}

@media (min-width: $bp-l) {
  .footer-top {
    display: grid;
    grid-template-columns: 0.75fr 2fr;
    column-gap: 80px;
  }

  .footer-nav,
  .footer-utility {
    grid-column: 2;
  }

  .footer-bottom {
    display: grid;
    grid-template-columns: 1fr 1.25fr 2.5fr 1fr;
    gap: 30px;
    align-items: center;
  }

  .footer-logo {
    margin: 0;
  }
}
