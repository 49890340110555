@use '../../abstracts/' as *;

.image-banner {
  margin-bottom: 80px;
}

.image-banner__content {
  padding: 0 24px;
}

.image-banner__media {
  position: relative;
  height: 450px;
  margin-bottom: 80px;
}

.image-banner__img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.image-banner__title {
  font-weight: $fontWeight-light;
}

.image-banner__summary {
  font-size: 1.125rem;
  margin: 32px 0;
}

.image-banner__action {
  .button {
    margin: 10px 0;
    width: 100%;
  }
}

.image-banner--light {
  .image-banner__title, .image-banner__content {
    color: $color-white;
  }
}

@media(min-width: $bp-mw) {
  .image-banner {
    display: grid;
    grid-template-columns: 35% 65%;
    align-items: center;
    margin-bottom: 0;
  }
  .image-banner__media {
    height: auto;
    margin-bottom: 0;
    aspect-ratio: 1 / 1;
  }

  .image-banner__summary {
    font-size: 1.25rem;
    margin: 16px 0;
  }

  .image-banner__action {
    margin-top: 92px;
    .button {
      margin: 0 5px;
      width: auto;
    }
  }

  .image-banner--right {
    grid-template-columns: 65% 35%;
    .image-banner__media {
      order: 2;
    }

    .image-banner__content {
      order: 1;
    }
  }
}