@use "../abstracts" as *;

.footer-nav {
  @extend %itemlist;
}

.footer-nav__menu, .footer-nav__sub {
  @extend %listreset;
}

.footer-nav__link {
  color: $color-white;
  font-weight: $fontWeight-normal;
  text-decoration: none;
}

.footer-nav__link-title {
  color: $color-white;
  display: none;
  font-weight: $fontWeight-normal;
  text-decoration: none;
}

.footer-nav__toggle {
  cursor: pointer;
  width: 100%;
  border: none;
  background: transparent;
  padding: 0;
  color: $color-white;
  position: relative;
  text-align: left;

  &:after {
    border-bottom: 1px solid;
    border-right: 1px solid;
    content: "";
    display: block;
    height: 7px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: rotate(45deg) translateY(-50%);
    width: 7px;
  }

  &.active:after {
    transform: rotate(-135deg) translateY(-50%);
  }
}

.footer-nav__link-title,
.footer-nav__toggle,
.footer-nav__menu > .footer-nav__item > .footer-nav__link {
  font-size: 1.5rem;
}

.footer-nav__sub {
  display: none;
  margin-top: 25px;
}

.footer-nav__menu > .footer-nav__item {
  border-bottom: 1px solid $color-white;
  padding-bottom: 15px;
  margin-bottom: 15px;
  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.footer-nav__sub .footer-nav__item {
  margin-bottom: 15px;
}

.footer-utility {
  text-transform: uppercase;
}

@media (min-width: $bp-l) {
  .footer-nav__menu {
    display: flex;
    column-gap: 40px;
    justify-content: space-between;
  }

  .footer-nav__link-title {
    display: block;
  }

  .footer-nav__toggle {
    display: none;
  }

  .footer-utility ul {
    display: flex;
    justify-content: space-between;

    li {
      margin-bottom: 0;
    }
  }

  .footer-nav__menu > .footer-nav__item {
    border: none;
    margin: 0;
    padding: 0;
  }

  .footer-nav__sub {
    display: inline-block !important;
  }

  .footer-nav__link-title,
  .footer-nav__toggle,
  .footer-nav__menu > .footer-nav__item > .footer-nav__link {
    font-size: 2rem;
  }

  .footer-utility {
    margin-top: 90px;
    margin-bottom: 70px;
  }
}
