@use '../abstracts' as *;

:root {
    @each $color, $colorVal in $colors {
        --color-#{$color}:#{$colorVal};
    }
    --sf-backgrоund-color:transparent;
    --sf-backgrоund-image:none;
    --sf-background-size:auto;
    --sf-background-position:0 0;
}

html,
button,
input,
select,
textarea {
    color: $color-base;
}

body {
    font-family: var(--font-primary);
    overflow-y: scroll;
    background: $body-bg;
}

::-moz-selection {
    background: $color-primary;
    color: $color-invert;
    text-shadow: none;
}

::selection {
    background: $color-primary;
    color: $color-invert;
    text-shadow: none;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    margin: 1em 0;
    padding: 0;
    border-top: 1px solid #ccc;
}

img {
    vertical-align: middle;
    width: auto;
    height: auto;
    max-width: 100%;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.hidden {
    display: none !important;
    visibility: hidden;
}

.sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    padding: 0;
    border: 0;
    width: 1px;
    height: 1px;
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
    position: static;
    overflow: visible;
    clip: auto;
    margin: 0;
    width: auto;
    height: auto;
}

.invisible {
    visibility: hidden;
}

.hide-mobile {
  display: none;
}

.hide-desktop {
  display: block;
}

figure {
    margin: 1em 0;
}

iframe {
    border: 0;
    max-width:100%;
}

@media(min-width: $bp-mw) {
  .hide-mobile {
    display: block;
  }
  
  .hide-desktop {
    display: none;
  }
}

/* ================================================================
   Talon Util Boilerplate Additions
================================================================ */

*:focus:not(:focus-visible) {
    outline:0;
}

.site-main {
    line-height:1.75;
}

svg.icon {
    fill:currentColor;
    vertical-align: middle;
}

.sf-Code {
    pre {
        display: block;
        padding: 9.5px;
        margin: 0 0 10px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #333;
        word-break: break-all;
        word-wrap: break-word;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 4px;
        white-space: pre-wrap;
        color: #666;
     }
}

.skip-link {
    @extend .sr-only;
    text-align: center;

    &:focus {
        position: static;
        display: block;
        color: $color-invert;
        background: $color-primary;
        padding: 20px 0;
        height: auto;
        width: 100%;
    }
}

.lazy-img, .lazy-bg {
    opacity: 0;
    transition:opacity $nav-transition-speed ease-in-out;

    &.inView { opacity:1; }

    .no-js & { display:none; }
}

%arrow-link {
    --border-color: #47799F;

    display: inline-flex;
    align-items: center;
    gap:.5rem;
    font-weight:$fontWeight-bold;
    text-decoration: none;
    text-transform: uppercase;
    color:$color-black;

    &:after {
        @include pseudo(block, static, '');
        border: solid var(--border-color);
        border-width: 2px 2px 0 0;
        margin-top: -3px;
        padding:4px;
        transform: rotate(45deg);
    }
    
    &:hover, &:focus-visible {
        text-decoration: underline;
        color:$color-primary;
    }
}

%arrow-link-left {
    --border-color: #47799F;
    @extend %arrow-link;

    &:before {
        @include pseudo(block, static, '');
        border: solid var(--border-color);
        border-width: 2px 2px 0 0;
        margin-top: -3px;
        padding:4px;
        transform: rotate(-135deg);
    }

    &:after {
        display:none;
    }
}

.arrow-link {
    @extend %arrow-link;
    
    &[style*="color:"] {
        --border-color: currentColor;
    }

    &:has(span) {
        display:inline-block;

        &:after {
            display: none;
        }

        span {
            @extend %arrow-link;
            &[style*="color:"] {
                --border-color: currentColor;
            }
        }
    }

    &.arrow-link--left {
        @extend %arrow-link-left;

        &[style*="color:"] {
            --border-color: currentColor;
        }
    
        &:has(span) {
            display:inline-block;
    
            &:after {
                display: none;
            }
    
            span {
                @extend %arrow-link;
                &[style*="color:"] {
                    --border-color: currentColor;
                }
            }
        }
    }
}

.bullet-list-white {
  li::marker {
    color: $color-white;
  }
}