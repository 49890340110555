@use "../abstracts/" as *;

$color-tab-border: #b8d7f3;

.tab-layout {
}

.tab-layout__list {
  display: flex;
  overflow: auto;
}

.tab-layout__tab {
  @extend %buttonreset;
  min-height: 40px;
  padding: 10px 15px;
  background: $color-dark-blue;
  color: $color-white;
  &.active {
    background: $color-white;
    color: $color-dark-blue;
  }
  &:hover,
  &:focus {
    background: $color-white;
    color: $color-dark-blue;
  }
}

.tab-layout__panel {
  display: none;
  padding: 80px 0 60px;
  &.active {
    display: block;
  }
}

.tab-layout--finder {
  .tab-layout__tab {
    background: $color-navy;

    &.active,
    &:focus,
    &:hover {
      background: $color-white;
    }
  }

  .tab-layout__panel {
    padding: 30px 0;
  }
}

@include breakpoint(l) {
  .tab-layout__tab {
  }

  .tab-layout__panel {
  }
}
