/* ================================================================
   Variables
   ================================================================ */

@font-face {
	font-family: "Yantramanav";
	src: url("/dist/fonts/Yantramanav-Regular.ttf") format("ttf");
    font-weight: 500;
}

@font-face {
	font-family: "Yantramanav Light";
	src: url("/dist/fonts/Yantramanav-Light.ttf") format("ttf");
	font-weight: 400;
}

@font-face {
	font-family: "Yantramanav Medium";
	src: url("/dist/fonts/Yantramanav-Medium.ttf") format("ttf");
	font-weight: 600;
}

@font-face {
	font-family: "Yantramanav Bold";
	src: url("/dist/fonts/Yantramanav-Bold.ttf") format("ttf");
	font-weight: 800;
}

@font-face {
	font-family: "Almarai";
	src: url("/dist/fonts/Almarai-Regular.ttf") format("ttf");
    font-weight: 500;
}

@font-face {
	font-family: "Almarai Light";
	src: url("/dist/fonts/Almarai-Light.ttf") format("ttf");
	font-weight: 400;
}

@font-face {
	font-family: "Almarai Bold";
	src: url("/dist/fonts/Almarai-Bold.ttf") format("ttf");
	font-weight: 800;
}

@font-face {
	font-family: "Roboto";
	src: url("/dist/fonts/Roboto-Regular.ttf") format("ttf");
    font-weight: 500;
}

@font-face {
	font-family: "Roboto Light";
	src: url("/dist/fonts/Roboto-Light.ttf") format("ttf");
	font-weight: 400;
}

@font-face {
	font-family: "Roboto Medium";
	src: url("/dist/fonts/Roboto-Medium.ttf") format("ttf");
	font-weight: 600;
}

@font-face {
	font-family: "Roboto Bold";
	src: url("/dist/fonts/Roboto-Bold.ttf") format("ttf");
	font-weight: 800;
}

// Color Variables
$body-bg: #fff;
$color-base: #000;
$color-invert: #fff;
$color-primary: #143854;
$color-secondary: #FFDD00;
$color-blue: #00487D;
$color-blue-highlight: #6E98C7;
$color-dark-blue: #0A2336;
$color-navy: #143854;
$color-light-grey: #F4F4F4;
$color-light-grey-2: #CED0D1;
$color-grey: #85898B;
$color-med-grey: #9DA1A2;
$color-dark-grey: #626668;
$color-yellow: #FFDD00;
$color-black: #000;
$color-white: #fff;
$color-red: #FF785A;

$colors: (
	"primary": $color-primary,
	"secondary": $color-secondary
);


// Typography
:root {
    --font-primary: 'Yantramanav', sans-serif;
    --font-secondary: 'Yantramanav Light', sans-serif;
    --font-heading: 'Yantramanav Bold', sans-serif;       
}

html[lang='ar'] {
    --font-primary: 'Almarai', sans-serif;
    --font-secondary: 'Almarai Light', sans-serif;
    --font-heading: 'Almarai Bold', sans-serif;
}

html[lang='el-gr'] {
    --font-primary: 'Roboto', sans-serif;
    --font-secondary: 'Roboto Light', sans-serif;
    --font-heading: 'Roboto Bold', sans-serif;
}


$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;

$typography-margin-bottom: 0.4em;


// Breakpoints
// You can convert these at http://pxtoem.com
$bp-s: 20em; // 320px
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938em; // 767px
$bp-m: 48em; // 768px
$bp-m-max: 59.938em; // 959px
$bp-mw: 60em; // 960px
$bp-mw-max: 71.188em; // 1139px
$bp-l: 72em; // 1152px
$bp-l-max: 81.188em; // 1299px
$bp-xl: 81.25em; // 1300px
$bp-xxl: 87.5em; // 1400px

$breakpoints: (
	xs: 0,
	sm: $bp-s,
	md: $bp-m,
	lg: $bp-l,
	xl: $bp-xl,
	xxl: $bp-xxl
);

$fontBreakpoints: (
  xs: 0,
  lg: $bp-l,
);

// 1330px
$grid-container-small: 83.125em;
$grid-container-large: 90em;

// Navigation Variables
$nav-transition-speed: 0.4s;
$nav-barBackground: #333;
$nav-mainLinkBackground: #666;
$nav-subLinkBackground: #999;


// Grid
$grid-gutter-em: 0.938em; // Changing grid gutter to em causes a compiling error :/

// Typography
$base-font-size: 16px;