@use '../abstracts' as *;

.header-container {
    position: relative;

    >.container {
        display: flex;
        flex-flow: row wrap;
        padding: 0;
    }
}

.header-container__utility {
    display: none;
    background: $color-primary;
    background: linear-gradient(90deg, rgba(20, 56, 84, 1) 40%, rgba(255, 221, 0, 1) 0%);
    flex: 1 100%;
    position: relative;

    a {
      text-decoration: none;
    }

    >.container {
        display: grid;
        grid-template-columns: 40% 60%;
    }
}

.header-container__utility-left {
    background: $color-primary;
    color: $color-white;
    position: relative;
    .sf-content-block {
        color: $color-yellow;
        a {
            color: $color-yellow;
            text-transform: uppercase;
        }
    }
}

.header-container__utility-right {
    background: $color-yellow;

    .icon {
        height: 30px;
        width: 30px;
        margin-left: 5px;
    }

    a {
        display: flex;
        align-items: center;
    }
}

.header-container__utility-left,
.header-container__utility-right {
    padding: 18px 32px;
    .sf-content-block {
        ul {    
            list-style: none;
            margin: 0;
            padding: 0;
    
            li {
                padding: 5px 0;
            }
        }
    
        a {
            font-weight: $fontWeight-semibold;
            text-transform: uppercase;
        }
    }
}

.header-container__logo {
    flex: 2;
    align-self: center;
    padding-left: 24px;

    img {
        max-width: 130px;
    }
}


//Styling for Mobile Menu
.main-nav__menu {
    .header-container__utility-left,
    .header-container__utility-right {
        padding: 15px;
    }
}

.header-container__nav {
  display: flex;
}

@media(min-width: $bp-l) {
    .header-container>.container {
        padding: 0 0.938em;
        gap: 60px;
        align-items: center;
    }


    .header-container__utility {
        display: block;
    }

    .header-container__utility-left {
        display: flex;
        align-items: center;
        justify-content: right;
        text-align: right;
        padding: 15px 40px 15px 0;

        &:after {
            content: "";
            display: inline-block;
            background: $color-primary;
            width: 40px;
            height: 100%;
            position: absolute;
            top: 0;
            right: -10px;
            transform: skewX(-15deg);
        }

    }

    .header-container__utility-right {
        padding: 15px 0 15px 40px;
    }

    .header-container__utility-left,
    .header-container__utility-right {
        .sf-content-block {
            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                li {
                    display: inline-block;
                    padding: 0;
                }
            }
        }
    }

    .header-container__logo {
        flex: 0 1 auto;
        padding-left: 0;

        img {
            max-width: 160px;
        }
    }

    .header-container__nav {
        flex: 2;
        padding: 22px 0;
    }
}