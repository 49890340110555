@use "../abstracts" as *;

.horizontal-nav {
  margin: 32px 0;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.horizontal-nav__menu {
  @extend %listreset;
}

.horizontal-nav__link {
  color: $color-white;
  font-weight: $fontWeight-normal;
  text-decoration: none;
}

@media (min-width: $bp-l) {
  .horizontal-nav {
    margin: 0;

    li {
      margin-bottom: 0;
    }

    ul {
      margin: 0;
    }
  }

  .horizontal-nav__menu {
    display: flex;
    column-gap: 16px;
  }
}
