@use '../../abstracts/' as *;

.large-banner__content {
  padding: 50px 24px;
}

.large-banner__action {
  margin-top: 25px;
}

.large-banner__media {
  position: relative;
  min-height: 450px;
}

.large-banner__img {
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@media(min-width: $bp-mw) {
  .large-banner__item {
    position: relative;    
    padding-top: 300px;
  }

  .large-banner__media {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .large-banner__content {
    background: $color-white;
    position: relative;
    width: 50%;
    z-index: 1;
    margin-left: auto;
    padding-right: 5em;
  }
}

@media(min-width: $bp-xxl) {
  .large-banner__item {
    padding-top: 432px;
  }

}