@use '../../abstracts' as *;

.hero-carousel {
	background: $color-primary;
	padding-top: 20px;
  position: relative;
}

.hero-carousel__controls {
  position: absolute;
  bottom: 272px;
  left: 0;
  display: flex;
  z-index: 10;
}

.hero-carousel__item {
  height: auto;
}

.hero-carousel__wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.hero-carousel__content {
  color: $color-white;
  margin-bottom: 25px;
  padding: 24px;

  h1,h2,h3 {
    color: $color-white;
  }
}

.hero-carousel__title {
  margin-bottom: 30px;
}

.hero-carousel__summary {
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 1px;
}

.hero-carousel__pause {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0 15px;
  vertical-align: middle;

  .icon-play {
    display: none;
  }

  &.paused {
    .icon-play {
      display: block;
    }
    
    .icon-pause {
      display: none;
    }
  }

  .icon {
    fill: $color-white;
    height: 18px;
    width: 18px;
  }
  
}

.swiper-button-prev, .swiper-button-next {
  display: inline-block;
  border: none;
  background: $color-secondary;
  cursor: pointer;
  height: 48px;
  width: 48px;

  .icon {
    fill: $color-primary;
    height: 24px;
    width: 24px;
    vertical-align: middle;
  }

  &.swiper-button-disabled {
    opacity: 0.7;

  }
}

.hero-carousel__media {
  position: relative;
  height: 320px;
  margin-top: auto;
  margin-left: 48px;

  .hero-carousel__image {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.hero-carousel__action {
  margin-top: 34px;
}

@media(min-width: $bp-sw) {
  .hero-carousel__media {
    height: 380px;
    margin-left: 100px;
  }

  .hero-carousel__controls {
    left: 52px;
    bottom: 332px;
  }

}

@media(min-width: $bp-m) {
  .hero-carousel__media {
    margin-left: 280px;
  }

  .hero-carousel__controls {
    left: 232px;
    bottom: 332px;
  }

}

@media(min-width: $bp-mw) {
  .hero-carousel {
    padding: 80px 56px 0 56px;
  }

  .hero-carousel__wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
  }

  .hero-carousel__controls {
    position: absolute;
    bottom: auto;
    top: 0;
    left: auto;
    right: 48px;
    width: 50%;
  }

  .hero-carousel__content {
    padding: 0 24px;
    padding-right: 80px;
  }

  .hero-carousel__media {
    margin-left: 0;
  }

}

@media(min-width: $bp-l) {
  .hero-carousel__media {
    height: 450px;
    margin-left: 0;
  }

  .hero-carousel__title {
    font-size: 3.75rem;
  }

  .hero-carousel__content {
    padding-right: 100px;
  }
}

@media(min-width: $bp-xl) {
  .hero-carousel__title {
    font-size: 4.5rem;
  }  

  .hero-carousel__media {
    height: 530px;
  }
}