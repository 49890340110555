@use '../abstracts/' as *;

$boxShadow: 4px 4px 15px rgba(0, 0, 0, 0.1);

.navigator {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-radius: 10px;
    gap:.5rem;
}

.navigator__item {
    display: flex;
    align-items: center;
    gap:2.8125rem;
    border-radius: 5px;
    background:transparent;
    will-change: background;
    transition:background .3s ease-in-out;
}

.navigator__toggle,
.navigator__link {
    @extend %buttonreset;
    display: flex;
    align-items: center;
    gap:0.625rem;
    width:100%;
    border-radius: 5px;
    padding:.5rem;
    background:$color-black;
    text-align: left;
    will-change: background, color;
    transition:background .3s ease-in-out, color .3s ease-in-out;
    cursor: pointer;

    &:hover, &:focus-visible, .navigator__item--active & {
        background:$color-primary;
        color:$color-white;

        .navigator__item-img {
            filter: brightness(0) invert(1);
        }   
    }
}

.navigator__item-img {
    max-width:50px;
}

.navigator__item-title {
    @include x-rem(font-size, 18px);
}

.navigator__item-content {
    display: none;
}

.navigator-wrap.navigator-wrap--dupe {
    display:none;
}

@media(min-width:$bp-l){
    .navigator-wrap.navigator-wrap--dupe {
        position:fixed;
        top:141px;
        z-index:98;
        width:100%;
        opacity:0;
        background:transparent;
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
        will-change: background, box-shadow;
        transition:background .3s ease-in-out, box-shadow .3s ease-in-out;

        .container {
            display: flex;
            justify-content: center;
        }

        &.sticky {
            display:block;
            opacity:1;
            box-shadow: $boxShadow;
            background:$color-white;

            .navigator {
                box-shadow: 0 0 0 0 rgba(0,0,0,0);
            }

            .navigator__item {
                padding:2rem 1.25rem;
            }

            .navigator__item-img {
                max-width:50px;
            }

            .navigator__item-content {
                display: none;
            }
        }
    }

    .navigator {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-shadow: $boxShadow;
    }
    
    .navigator__item {
        background:none;
        transition:padding .3s ease-in-out;

        &.open,
        &.active {
            flex-basis: 40%;
            background:#F5F5F5;
        }

        .navigator & {
            padding:4rem 2.625rem;
        }
    }

    .navigator__toggle,
    .navigator__link {
        width:auto;
        flex-direction: column;
        padding:0;
        background:none;
        color:$color-black;

        &:hover, &:focus-visible, .navigator__item--active & {
            background:none;
            color:$color-primary;

            .navigator__item-img {
                filter: brightness(1) invert(0);
            }   
        }
    }
    
    .navigator__item-img {
        max-width:70px;
        will-change: max-width;
        transition:max-width .3s ease-in-out;
    }

    .navigator__item-content {
        .open &,
        .active & { 
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            @include x-rem(gap, 10px);
        }
    
        .button {
            @include x-rem(margin-top, 10px);
        }
    }
}