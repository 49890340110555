@use '../abstracts' as *;

.main-nav-toggle {
    @extend %buttonreset;
    background: $color-primary;
    padding: 16px 19px;


    &.active {
        background: $color-white;

        .main-nav-toggle__icon {
            fill: $color-primary !important;

            &.close-menu {
                display: block;
            }

            &.open-menu {
                display: none;
            }
        }
    }
}

.main-nav-toggle__icon {
    width: 32px;
    height: 32px;
    display: inline-block;
    position: relative;
    fill: $color-white !important;

    &.close-menu {
        display: none;
    }

    &.open-menu {
        display: block;
    }
}

.main-nav {
    display: none;
    width: 100%;

    &.main-nav--open {
        background: $color-white;
        box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.3);
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
    }
    z-index: 99;
}

.main-nav__menu {
    display: flex;
    flex-direction: column;

    &:is(ul) {
        @extend %listreset;
    }

    & > .main-nav__item {
        border-top:1px solid $color-white;
    }
}

.main-nav__item {
    position:relative;

    .main-nav__toggle, .main-nav__link {
        padding: 10px 15px;
    }
}


.main-nav__item-wrapper {
  display: flex;
  align-items: center;
}
.main-nav__toggle {
    @extend %buttonreset;
    position: relative;
    display: flex;
    gap:.5rem;
    letter-spacing: 1px;
    padding-right:1rem;
    text-align: left;
    text-transform: uppercase;
    color:currentColor;
    width: 100%;

    &:after {
        @include pseudo();
        top: 50%;
        right: 10px;
        width:7px;
        height:7px;
        border-bottom:1px solid currentColor;
        border-right:1px solid currentColor;
        transform:rotate(-45deg) translateY(-50%);
    }

    &.active {
        &:after {
            transform: rotate(-135deg);
        }        
    } 
}

.main-nav__toggle--sub {
  &:after {
    position: relative;
  }
}

.main-nav__link {
    display: inline-block;
    color:currentColor;
    font-weight: $fontWeight-normal;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    
    &:is(a) {
        text-decoration: none;
    }
}

.main-nav__item-toggle {
    @extend %buttonreset;
    position: relative;
    display: flex;
    gap:.5rem;
    padding-right:1rem;
    text-align: left;

    &:after {
        @include pseudo();
        top:4px;
        right:0;
        padding:4px;
        border-bottom:1px solid $color-primary;
        border-right:1px solid $color-primary;
        transform:rotate(45deg);
    }
}

.main-nav__sub {
    @extend %listreset;
    display:none;
    flex-direction: column;
    padding:.5rem 1rem;
}

.main-nav__item-sub {
    display: none;
    list-style: none;
    margin: 0.5em 0 0 0;
    padding: 0 0 0 15px;
}

// Level 1
.main-nav__menu > .main-nav__item {
    & > .main-nav__link,
    & > .main-nav__toggle {
        text-transform: uppercase;
    }
}

// Level 2 
.main-nav__sub .main-nav__toggle {
    display:flex;
    align-items: center;
    gap:1.25rem;
    color:currentColor;

    &:after {
        @include pseudo(block, static, '');
        width:9px;
        height:9px;
        margin-left:auto;
        border-bottom:1px solid currentColor;
        border-right:1px solid currentColor;
        transform:rotate(45deg);
    }

    &.active:after {
        transform: rotate(-135deg);
    }

    .main-nav__item-text {
        display:inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color:currentColor;

        &:before {
            content:attr(data-text);
            height:0;
            visibility: hidden;
            font-weight:$fontWeight-medium;
            overflow: hidden;
            user-select:none;
            pointer-events: none;
            color:currentColor;
    
            @media speech {
                display:none;
            }
        }
    }

    &:hover, &:focus-visible {
        .main-nav__item-text {
            font-weight:$fontWeight-medium;
        }
    }
}

@media(min-width:$bp-l){
    .main-nav-toggle {
        display: none;
    }

    .main-nav, .main-nav.main-nav--open {
        box-shadow: none;
        display: inline-block;
        position: relative;
        top: auto;

        > .main-nav__menu > .main-nav__item  {
            > .main-nav__toggle, > .main-nav__link {
                padding: 10px 32px;
            }            
        }

    }

    .main-nav__toggle:after {
        top: 45%;
        transform:rotate(45deg) translateY(-50%);
    }

    .main-nav__menu {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }

    .main-nav__item-utility {
        display: none;
    }

    // Level 1
    .main-nav__menu > .main-nav__item {
        border-top:0;
        padding-top:0;
    }

    // Level 2
    .main-nav__sub {
        position:absolute;
        top:100%;
        left:0;
        width:auto;
        min-width: 220px;
        padding: 15px;
        background:$color-white;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
        color:$color-black;

        .main-nav__link,
        .main-nav__toggle {
            &:hover, &:focus-visible {
                color:$color-primary;
            }
        }

        .main-nav__sub {
            top:0;
            left:100%;
        }
    }
    .main-nav__menu > .main-nav__item > .main-nav__sub {
        &:before {
            @include pseudo();
            top:-20px;
            left:12%;
            border:10px solid transparent;
            border-left-width:7px;
            border-right-width:7px;
            border-bottom-color:$color-white;
        }
    }

    // target last 4 child items
    .main-nav__menu > .main-nav__item:nth-last-child(-n+3) .main-nav__sub {
        left:auto;
        right:0;

        &:before {
            left:auto;
            right:12%;
        }

        .main-nav__sub {
            right:100%;
        }
    }
}