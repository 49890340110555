@use "../../abstracts" as *;

.registration .tns-nav {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  justify-content: center;

  > button {
    background: $color-white;
    border: none;
    border-radius: 50%;
    height: 12px;
    opacity: 0.3;
    width: 10px;

    &.tns-nav-active {
      opacity: 1;
    }
  }
}

.registration__form-group--checkbox {
  display: grid;
  grid-template-columns: auto 2fr;
  column-gap: 20px;
  margin-top: 60px;
  .registration__form-label {
    font-size: 1rem;
  }

  .registration__form-desc {
    font-size: 0.8313rem;
    grid-column: 2;
    margin-top: 15px;
  }
}

.registration__services {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.registration__services-item {
  height: auto;
}

.registration__services-btn {
  display: block;
  background: $color-dark-blue;
  border: none;
  color: $color-white;
  padding: 40px 24px;
  position: relative;
  height: 100%;
  width: 100%;
}

.registration__services-title,
.registration__form-title {
  font-size: 1.25rem;
  margin-bottom: 24px;
}

.registraton__services-desc {
  ul {
    margin: 0;
    padding-left: 20px;
    line-height: 24px;
    list-style-type: disc;
  }
}

.registration__services-label {
  color: $color-secondary;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.registration__services-added, .registration__services-check-icon {
  display: none;
}


.registration__services-add {
  margin-top: 40px;
  text-align: right;
}

.registration__services-add-chk {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked {   
    ~ .registration__services-added, ~ .registration__services-check-icon {
      display: inline-block;
    }

    ~ .registration__services-add-label, ~ .registration__services-add-icon {
      display: none;
    }
  }
}



.registration__services-add-icon, .registration__services-check-icon {
  fill: $color-secondary;
  margin-left: 5px;
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

.registration__services-media {
  margin-bottom: 42px;
}

.registration__services-img {
  max-height: 60px;
}

@media (min-width: $bp-mw) {
  .registration__services {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .registration__services-btn {
    transition: 0.3s background linear;
    &:hover,
    &:focus {
      background: $color-white;
      color: $color-base;

      .registration__services-label {
        color: $color-base;
      }

      .registration__services-add-icon {
        fill: $color-base;
      }
    }
  }
}
