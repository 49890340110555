@use "../../abstracts/" as *;

.battery-finder {
  background: $color-dark-blue;
  color: $color-white;
  padding: 32px;
  max-width: 950px;
  margin-left: auto;
}

.battery-finder__title {
  margin-bottom: 40px;
}

.battery-finder--modal {
  background: transparent;
  padding: 0;
  max-width: 100%;
  margin-left: 0;

  .battery-finder__title {
    margin-bottom: 100px;
  }

  .battery-finder__action {
    margin-top: 30px;
  }
}

.battery-finder__vehicle,
.battery-finder__tabs {
  display: flex;
  @extend %listreset;
}

.battery-finder__vehicle {
  gap: 8px;
  margin-bottom: 20px;
  overflow-x: auto;
}

.battery-finder__vehicle-type {
  border: 1px solid $color-white;
  background: transparent;
  border-radius: 24px;
  color: $color-white;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 8px 12px;
  transition: 0.3s all ease-in;

  &:hover,
  &:focus-visible,
  &.active {
    background: $color-white;
    color: $color-navy;
  }
}

.battery-finder__group--main {
  display: inline-block;
}

.battery-finder__group--sub {
  display: none;
}

.battery-finder__label {
  color: $color-light-grey-2;
  font-size: 0.8313rem;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.battery-code__icon--products {
  fill: $color-white;
  height: 16px;
  width: 16px;
  margin-left: 5px;
}

.battery-finder__select {
  border: none;
  border-bottom: 1px solid $color-white;
  color: $color-white;
  font-weight: $fontWeight-semibold;
  display: block;
  height: 35px;
  width: 100%;
  background: transparent;
  cursor: pointer;
  text-align: left;

  &:disabled {
    font-weight: $fontWeight-normal;
    opacity: 0.5;
  }

  option {
    color: $color-base;
  }
}

.battery-finder__search-input {
  display: block;
  height: 35px;
  width: 95%;
  margin: 10px auto;
}

.battery-finder__option {
  display: block;
  border: none;
  background: transparent;
  color: $color-base;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;

  &:hover,
  &:focus-visible {
    background: $color-primary;
    color: $color-white;
  }
}

.battery-finder__action {
  margin-top: 30px;
}

.battery-finder__leisure-text {
  background: $color-yellow;
  color: $color-base;
  padding: 15px;
}

.battery-finder__panel-message {
  background: $color-yellow;
  color: $color-base;
  padding: 15px;
}

//Plate No,Tecdoc,Battery Code Fields
.battery-finder__panel {
  &.hide {
    display: none;
  }
}
.battery-finder__panel-group {
  display: flex;
  align-items: center;
  gap: 15px;
}

@media (min-width: $bp-mw) {
  .battery-finder__form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
  }

  .battery-finder__action {
    margin-top: 0;
  }

  .battery-finder__group {
    width: 31%;
  }

  .battery-finder--modal {
    .battery-finder__content {
      max-width: 800px;
      margin: 0 auto;
    }

    .battery-finder__group {
      width: auto;
    }    

    .battery-finder__form {
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 1fr));
      gap: 48px;
    }

    .battery-finder__action {
      display: inline-block;
      grid-column-start: 1;
      grid-column-end: 3;
      text-align: right;
    }
  }
}

@media (min-width: $bp-l) {
  .battery-finder--modal {
    .battery-finder__content {
      max-width: 968px;
      margin: 0 auto;
    }
  }
}
