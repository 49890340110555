@use '../abstracts' as *;

.section-nav {
  background: $color-dark-blue;
  padding: 40px 32px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;   
  }
}

.section-nav__item {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.section-nav__link {
  color: $color-white;

  &.active {
    display: flex;
    align-items: flex-end;
    font-size: 1.5rem;
    position: relative;
    justify-content: space-between;


    &:after {
      display: inline-block;
      content: url('data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 20 18"><title>arrow-right</title><path fill="%23FFFFFF" d="M12.15 0.901l-1.125 1.125 6.075 6.188h-17.1v1.575h17.1l-6.075 6.188 1.125 1.125 8.1-8.1-8.1-8.1z"></path></svg>');
    }
  }
}

@media(min-width: $bp-mw) {
  .section-nav__link.active {
    font-size: 2rem;
  }
}